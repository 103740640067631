import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import { faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import Tooltip from "./Tooltip";

type AppDropdownProps = {
  currentApp?: ListAppsResponseItem;
  onSelect: (key: string | null) => void;
};

function AppDropdown({ currentApp, onSelect }: AppDropdownProps) {
  const { apps, activeApp } = useGlobal();

  if (apps !== undefined && apps.length === 0 && !activeApp && !currentApp) {
    return <span className="text-muted">No apps set up yet</span>;
  }

  return (
    <>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle as="a">{currentApp?.name || activeApp?.name}</Dropdown.Toggle>
        <Dropdown.Menu className="lh-base">
          <Dropdown.Header>Switch app</Dropdown.Header>
          {apps?.map((app) => (
            <Dropdown.Item key={app.id} eventKey={app.slug} active={app.id === (currentApp?.id || activeApp?.id)}>
              {app.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {activeApp?.suspended && (
        <Tooltip tooltip="We've temporarily paused data ingestion for this app" placement="bottom">
          <small className="d-none d-sm-inline ms-3 cursor-default">
            <Badge pill bg="danger" className="outline-danger" style={{ marginBottom: "-0.35em" }}>
              <FontAwesomeIcon icon={faPause} style={{ position: "relative", top: 0.5 }} />
            </Badge>
          </small>
        </Tooltip>
      )}
    </>
  );
}

export default AppDropdown;
