import {
  faAngleRight,
  faBug,
  faChartSimple,
  faCheck,
  faEye,
  faEyeSlash,
  faRotateLeft,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import "./Errors.css";
import { AppEnvItem, ErrorsTableItem, ListAppsResponseItem } from "./backend";
import ErrorsBarChart from "./charts/ErrorsBarChart";
import ActionsDropdown from "./components/ActionsDropdown";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import StatusCode from "./components/StatusCode";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import { useConfirmation } from "./contexts/ConfirmationContext";
import { useGlobal } from "./contexts/GlobalContext";
import useDashboardFilters from "./hooks/useDashboardFilters";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import EndpointErrorModal from "./modals/EndpointErrorModal";
import { EndpointError } from "./types/Endpoint";
import { isCustomPeriod } from "./utils/period";

type ErrorsTableProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  period: string;
};

function ErrorsTable({ app, env, period }: ErrorsTableProps) {
  const { backendClient, isDemo, isTeamAdmin, timezone } = useGlobal();
  const { maybeConfirm } = useConfirmation();
  const [sortedData, setSortedData] = useState<ErrorsTableItem[]>();
  const [showExpected, setShowExpected] = useState(false);
  const [modalEndpointError, setModalEndpointError] = useState<EndpointError>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ErrorsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "requests", direction: "desc" });
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  const queryParams = { appId: app.id, appEnv: env?.slug, period, timezone };
  const query = useQuery({
    queryKey: ["errorsTable", queryParams],
    queryFn: () => backendClient!.errors.getErrorsTable(queryParams),
    enabled: !!backendClient,
  });

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) =>
          searchTerms.every(
            (term) =>
              item.status_code.toString() == term ||
              item.status_text.toLowerCase().includes(term) ||
              item.path.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(sortedData);
    }
  }, [search, sortedData]);

  useEffect(() => {
    const method = searchParams.get("method");
    const path = searchParams.get("path");
    const status_code = searchParams.get("status_code");
    if (method && path && status_code && query.isSuccess) {
      const item = query.data.find(
        (item) => item.method === method && item.path === path && item.status_code === parseInt(status_code),
      );
      if (item) {
        setModalEndpointError({
          id: null,
          method: item.method,
          path: item.path,
          status_code: item.status_code,
          status_text: item.status_text,
          expected: item.expected,
        });
        setSearchParams(
          (searchParams) => {
            searchParams.delete("method");
            searchParams.delete("path");
            searchParams.delete("status_code");
            return searchParams;
          },
          { replace: true },
        );
      }
    }
  }, [query.isSuccess, searchParams]);

  const sortData = (data: ErrorsTableItem[], sortBy: SortBy) => {
    const sort = (a: ErrorsTableItem, b: ErrorsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.expected && !b.expected) {
        return 1;
      } else if (!a.expected && b.expected) {
        return -1;
      }

      if (sortBy.column === "status_code") {
        return (a.status_code - b.status_code) * sign;
      } else if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      } else if (sortBy.column === "requests") {
        return (a.request_count - b.request_count) * sign;
      } else if (sortBy.column === "affected_consumers") {
        return (a.affected_consumers - b.affected_consumers) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const toggleExpected = async (endpointError: ErrorsTableItem, expected: boolean) => {
    if (backendClient) {
      await backendClient.endpoints.updateEndpointErrorConfig({
        appId: app.id,
        requestBody: {
          method: endpointError.method,
          path: endpointError.path,
          status_code: endpointError.status_code,
          expected,
        },
      });
      await queryClient.refetchQueries({ type: "active" });
    }
  };

  const unexpectedErrors = query.data?.filter((item) => !item.expected);
  const maxRequestCount = unexpectedErrors?.reduce((max, item) => Math.max(max, item.request_count), 0);
  const maxAffectedConsumers = unexpectedErrors?.reduce((max, item) => Math.max(max, item.affected_consumers), 0);
  const expectedErrorItems = searchResult?.filter((item) => item.expected);
  const tableFooter =
    expectedErrorItems && expectedErrorItems.length > 0 ? (
      <div className="small">
        {!showExpected ? (
          <Button variant="link" onClick={() => setShowExpected(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show {expectedErrorItems.length} expected error
            {expectedErrorItems.length > 1 ? "s" : ""}
          </Button>
        ) : (
          <Button variant="link" onClick={() => setShowExpected(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide {expectedErrorItems.length} expected error
            {expectedErrorItems.length > 1 ? "s" : ""}
          </Button>
        )}
      </div>
    ) : undefined;
  const searchHeader =
    query.isSuccess && query.data.length > 10 ? (
      <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search errors" />
    ) : undefined;

  return (
    <div className="ErrorsTable">
      <EndpointErrorModal
        app={app}
        env={env}
        period={period}
        endpointError={modalEndpointError}
        setEndpointError={setModalEndpointError}
      />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={query.isSuccess && query.data.length > 0 && searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ minWidth: "40px" }}></th>
            <ColumnHeader name="status_code" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Response status
            </ColumnHeader>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Occurrences
            </ColumnHeader>
            <ColumnHeader name="affected_consumers" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Consumers
            </ColumnHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter((item) => !item.expected || showExpected)
            .map((item) => (
              <tr
                key={`${item.status_code} ${item.method} ${item.path}`}
                className="cursor-pointer"
                onClick={() =>
                  setModalEndpointError({
                    id: null,
                    method: item.method,
                    path: item.path,
                    status_code: item.status_code,
                    status_text: item.status_text,
                    expected: item.expected,
                  })
                }
              >
                <td width={40}>
                  <FontAwesomeIcon icon={item.expected ? faCheck : faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td>
                  <StatusCode code={item.status_code} text={item.status_text} />
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  value={item.request_count}
                  maxValue={maxRequestCount}
                  showBar={!item.expected && sortBy.column === "requests"}
                >
                  {item.request_count.toLocaleString()}
                </TableCellWithBar>
                <TableCellWithBar
                  value={item.affected_consumers}
                  maxValue={maxAffectedConsumers}
                  showBar={!item.expected && sortBy.column === "affected_consumers"}
                >
                  {item.affected_consumers > 0 ? item.affected_consumers.toLocaleString() : "-"}
                </TableCellWithBar>
                <td className="text-end align-middle pe-4">
                  {isTeamAdminOrDemo && (
                    <ActionsDropdown>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setModalEndpointError({
                            id: null,
                            method: item.method,
                            path: item.path,
                            status_code: item.status_code,
                            status_text: item.status_text,
                            expected: item.expected,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                        Error insights
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {!item.expected && (
                        <Dropdown.Item
                          as="button"
                          disabled={!isTeamAdmin || item.status_code >= 500}
                          onClick={() =>
                            maybeConfirm({
                              title: "Mark error as expected",
                              body: (
                                <p>
                                  This will exclude the {item.status_code} status code from error rate calculations for
                                  this endpoint, and requests with the {item.status_code} response status code will be
                                  considered successful.
                                </p>
                              ),
                              onConfirm: () => toggleExpected(item, true),
                              dontAskAgainKey: "markErrorAsExpected",
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} fixedWidth className="text-secondary" />
                          Mark as expected
                        </Dropdown.Item>
                      )}
                      {item.expected && (
                        <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleExpected(item, false)}>
                          <FontAwesomeIcon icon={faRotateLeft} fixedWidth className="text-secondary" />
                          Mark as unexpected
                        </Dropdown.Item>
                      )}
                    </ActionsDropdown>
                  )}
                </td>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td width={40}></td>
              <td colSpan={4} className="text-center py-6">
                Your search didn't match any errors.
              </td>
              <td width={40}></td>
            </tr>
          )}
          {query.isSuccess &&
            (query.data.length == 0 || query.data.every((item) => item.expected && !showExpected)) && (
              <tr>
                <td width={40}></td>
                <td colSpan={4} className="text-center py-6">
                  No unsuccessful requests in the selected period.
                </td>
                <td width={40}></td>
              </tr>
            )}
          {query.isPending &&
            [...Array(3)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td width={40}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "30%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="danger"
                    style={{ width: "2.5rem", borderRadius: "0.375rem", marginRight: "0.75rem" }}
                  />
                  <Placeholder xs={5} />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    style={{ width: "3.5rem", borderRadius: "0.375rem", marginRight: "0.75rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={6} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td></td>
              </Placeholder>
            ))}
        </tbody>
      </TableCard>
    </div>
  );
}

function ErrorsTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="ErrorsTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Errors</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faBug}>
          <h2>Keep track of client & server errors</h2>
          <p className="mt-4">
            Get an overview of unsuccessful requests made by your API consumers, whether expected or not. Dive into
            details of each specific error, understand the frequency of occurrence and which consumers are affected.
          </p>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Errors() {
  const { apps, activeApp } = useGlobal();
  const { app, appSlug, env, setEnv, period, setPeriod, resetPeriod } = useDashboardFilters();
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  useEffect(() => {
    if (app) {
      document.title = `Errors - ${app.name} - Apitally`;
    } else {
      document.title = `Errors - Apitally`;
    }
  }, [app]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <ErrorsTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/errors/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/errors/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Errors">
        <CustomPeriodModal
          show={showCustomPeriodModal}
          setShow={setShowCustomPeriodModal}
          period={period}
          setPeriod={setPeriod}
        />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Errors</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/errors/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges
          period={period}
          resetPeriod={resetPeriod}
          env={env?.slug}
          clearEnv={() => setEnv(undefined)}
          className="mb-4"
        />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="py-2">
            <ErrorsBarChart app={app} env={env} period={period} />
          </Card.Body>
        </Card>
        <ErrorsTable app={app} env={env} period={period} />
      </div>
    </MainLayout>
  );
}

export default Errors;
