import {
  faAngleRight,
  faBan,
  faChartSimple,
  faCog,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faPenToSquare,
  faQuestionCircle,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import { Navigate, useNavigate } from "react-router-dom";

import "./Performance.scss";
import { AppEnvItem, ListAppsResponseItem, PerformanceEndpointsTableItem } from "./backend";
import ApdexScoreLineChart from "./charts/ApdexScoreLineChart";
import ResponseTimeLineChart from "./charts/ResponseTimeLineChart";
import ActionsDropdown from "./components/ActionsDropdown";
import ApdexScoreFace from "./components/ApdexScoreFace";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import Metric from "./components/Metric";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import { useGlobal } from "./contexts/GlobalContext";
import useDashboardFilters from "./hooks/useDashboardFilters";
import ApdexScoreExplanationModal from "./modals/ApdexScoreExplanationModal";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import EndpointConfigModal from "./modals/EndpointConfigModal";
import EndpointModal from "./modals/EndpointModal";
import { Endpoint } from "./types/Endpoint";
import { formatResponseTime } from "./utils/numbers";
import { isCustomPeriod } from "./utils/period";

function PerformanceMetricDropdown({ children }: { children: React.ReactNode[] }) {
  return (
    <Dropdown align="end" className="PerformanceMetricDropdown ms-auto no-caret">
      <Dropdown.Toggle variant="outline-light border-0">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </Dropdown.Toggle>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
}

type PerformanceEndpointsTableProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  period: string;
};

function PerformanceEndpointsTable({ app, env, period }: PerformanceEndpointsTableProps) {
  const { backendClient, isTeamAdmin, isDemo, timezone } = useGlobal();
  const [showExcluded, setShowExcluded] = useState(false);
  const [modalEndpoint, setModalEndpoint] = useState<Endpoint>();
  const [endpointConfigModalEndpoint, setEndpointConfigModalEndpoint] = useState<Endpoint>();
  const [sortedData, setSortedData] = useState<PerformanceEndpointsTableItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<PerformanceEndpointsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "apdex_score", direction: "asc" });
  const queryClient = useQueryClient();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["performanceEndpointsTable", queryParams],
    queryFn: () => backendClient!.performance.getPerformanceEndpointsTable(queryParams),
    enabled: !!backendClient,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const includedData = query.data?.filter((item) => !item.excluded);
  const maxInverseApdexScore = includedData?.reduce((max, item) => Math.max(max, 1 - item.apdex_score), 0);
  const maxRequestCount = includedData?.reduce((max, item) => Math.max(max, item.total_request_count), 0);
  const maxResponseTimeP50 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p50), 0);
  const maxResponseTimeP75 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p75), 0);
  const maxResponseTimeP95 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p95), 0);
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) => searchTerms.every((term) => item.path.toLowerCase().includes(term))),
      );
    } else {
      setSearchResult(sortedData);
    }
  }, [search, sortedData]);

  useEffect(() => {
    setSearch("");
  }, [app]);

  const sortData = (data: PerformanceEndpointsTableItem[], sortBy: SortBy) => {
    const sort = (a: PerformanceEndpointsTableItem, b: PerformanceEndpointsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.excluded && !b.excluded) {
        return 1;
      } else if (!a.excluded && b.excluded) {
        return -1;
      }

      if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      }

      if (a.total_request_count > 0 && b.total_request_count === 0) {
        return 1 * sign;
      } else if (a.total_request_count === 0 && b.total_request_count > 0) {
        return -1 * sign;
      }

      if (sortBy.column === "apdex_score") {
        return (a.apdex_score - b.apdex_score) * sign;
      } else if (sortBy.column === "requests") {
        return (a.total_request_count - b.total_request_count) * sign;
      } else if (sortBy.column === "response_time_p50") {
        return (a.response_time_p50 - b.response_time_p50) * sign;
      } else if (sortBy.column === "response_time_p75") {
        return (a.response_time_p75 - b.response_time_p75) * sign;
      } else if (sortBy.column === "response_time_p95") {
        return (a.response_time_p95 - b.response_time_p95) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const excludedItems = searchResult?.filter((item) => item.excluded);
  const tableFooter =
    excludedItems && excludedItems.length > 0 ? (
      <div className="small">
        {!showExcluded ? (
          <Button variant="link" onClick={() => setShowExcluded(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show {excludedItems.length} excluded endpoint
            {excludedItems.length > 1 ? "s" : ""}
          </Button>
        ) : (
          <Button variant="link" onClick={() => setShowExcluded(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide {excludedItems.length} excluded endpoint
            {excludedItems.length > 1 ? "s" : ""}
          </Button>
        )}
      </div>
    ) : undefined;
  const searchHeader =
    query.isSuccess && query.data.length > 10 ? (
      <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search endpoints" />
    ) : undefined;

  return (
    <div className="PerformanceEndpointsTable">
      <EndpointModal
        app={app}
        env={env}
        period={period}
        endpoint={modalEndpoint}
        setEndpoint={setModalEndpoint}
        initialTab="response-times"
      />
      <EndpointConfigModal
        app={app}
        endpoint={endpointConfigModalEndpoint}
        setEndpoint={setEndpointConfigModalEndpoint}
        refresh={refresh}
      />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ minWidth: "40px" }}></th>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="apdex_score" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Apdex score
            </ColumnHeader>
            <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Requests
            </ColumnHeader>
            <ColumnHeader name="response_time_p50" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p50
            </ColumnHeader>
            <ColumnHeader name="response_time_p75" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p75
            </ColumnHeader>
            <ColumnHeader name="response_time_p95" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p95
            </ColumnHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter((item) => !item.excluded || showExcluded)
            .map((item) => (
              <tr
                key={`${item.method} ${item.path}`}
                className="cursor-pointer"
                onClick={() =>
                  setModalEndpoint({
                    id: item.id,
                    method: item.method,
                    path: item.path,
                    excluded: item.excluded,
                    target_response_time_ms: item.target_response_time_ms,
                  })
                }
              >
                <td width={40}>
                  <FontAwesomeIcon icon={item.excluded ? faBan : faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  showBar={
                    !item.excluded &&
                    item.total_request_count > 0 &&
                    (sortBy.column === "apdex_score" || sortBy.column === "endpoint")
                  }
                  value={1 - item.apdex_score}
                  maxValue={maxInverseApdexScore}
                >
                  {item.total_request_count > 0
                    ? item.apdex_score.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })
                    : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "requests"}
                  value={item.total_request_count}
                  maxValue={maxRequestCount}
                >
                  {item.total_request_count.toLocaleString()}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p50"}
                  value={item.response_time_p50}
                  maxValue={maxResponseTimeP50}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p50) : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p75"}
                  value={item.response_time_p75}
                  maxValue={maxResponseTimeP75}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p75) : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p95"}
                  value={item.response_time_p95}
                  maxValue={maxResponseTimeP95}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p95) : "-"}
                </TableCellWithBar>
                <td className="text-end align-middle pe-4">
                  {isTeamAdminOrDemo && (
                    <ActionsDropdown>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setModalEndpoint({
                            id: item.id,
                            method: item.method,
                            path: item.path,
                            excluded: item.excluded,
                            target_response_time_ms: item.target_response_time_ms,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                        Endpoint insights
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setEndpointConfigModalEndpoint({
                            method: item.method,
                            path: item.path,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                        Endpoint settings
                      </Dropdown.Item>
                    </ActionsDropdown>
                  )}
                </td>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td width={40}></td>
              <td colSpan={6} className="text-center py-6">
                Your search didn't match any endpoints.
              </td>
              <td width={40}></td>
            </tr>
          )}
          {query.isSuccess && query.data.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center py-6">
                There haven't been any requests in the selected period.
              </td>
            </tr>
          )}
          {query.isPending &&
            [...Array(5)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td width={40}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    className="me-4"
                    style={{ width: "3.5rem", borderRadius: "0.375rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td></td>
              </Placeholder>
            ))}
        </tbody>
      </TableCard>
    </div>
  );
}

function PerformanceTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="PerformanceTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Performance</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faChartSimple} iconStyle={{ right: "-40px" }}>
          <h2>Easily understand your APIs performance</h2>
          <p className="mt-4">
            Get actionable usage and performance insights about your API, each endpoint and individual API consumers,
            allowing you to make informed, data-driven engineering and product decisions.
          </p>
          <ul className="my-4">
            <li>
              <b>Requests:</b> Track number of requests over time, per endpoint and per consumer
            </li>
            <li>
              <b>Error rates:</b> Monitor client & server errors, get a breakdown of returned status codes
            </li>
            <li>
              <b>Response times:</b> Understand the performance of each endpoint and identify bottlenecks
            </li>
            <li>
              <b>Payload sizes:</b> Keep track of request & response sizes and total data transferred
            </li>
          </ul>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Performance() {
  const { apps, activeApp, backendClient, timezone } = useGlobal();
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const { app, appSlug, env, setEnv, period, setPeriod, resetPeriod } = useDashboardFilters();
  const [showApdexScoreExplanationModal, setShowApdexScoreExplanationModal] = useState(false);
  const [responseTimeMetricPercentile, setResponseTimeMetricPercentile] = useState(
    localStorage.getItem("performanceResponseTimeMetricPercentile") || "p95",
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    period,
    timezone,
  };
  const metricsQuery = useQuery({
    queryKey: ["performanceMetrics", metricsQueryParams],
    queryFn: () => backendClient!.performance.getPerformanceMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const responseTimeMetric =
    responseTimeMetricPercentile === "p50"
      ? metricsQuery.data?.response_time_p50
      : responseTimeMetricPercentile === "p75"
        ? metricsQuery.data?.response_time_p75
        : metricsQuery.data?.response_time_p95;
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  useEffect(() => {
    if (app) {
      document.title = `Performance - ${app.name} - Apitally`;
    } else {
      document.title = `Performance - Apitally`;
    }
  }, [app]);

  useEffect(() => {
    localStorage.setItem("performanceResponseTimeMetricPercentile", responseTimeMetricPercentile);
  }, [responseTimeMetricPercentile]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <PerformanceTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/performance/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/performance/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Performance">
        <ApdexScoreExplanationModal show={showApdexScoreExplanationModal} setShow={setShowApdexScoreExplanationModal} />
        <CustomPeriodModal
          show={showCustomPeriodModal}
          setShow={setShowCustomPeriodModal}
          period={period}
          setPeriod={setPeriod}
        />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Performance</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/performance/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges
          period={period}
          resetPeriod={() => resetPeriod()}
          env={env?.slug}
          clearEnv={() => setEnv(undefined)}
        />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="p-0">
            <Container fluid>
              <Row>
                <Col sm={12} lg={6} className="metric-item">
                  <div className="d-flex">
                    <Metric
                      label="Apdex score"
                      value={
                        metricsQuery.data?.total_request_count ? (
                          <>
                            {metricsQuery.data?.apdex_score.toLocaleString(undefined, {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                            <ApdexScoreFace apdexScore={metricsQuery.data?.apdex_score} className="ms-2 small" />
                          </>
                        ) : (
                          "-"
                        )
                      }
                      description="Based on configured response time threshold"
                    />
                    <PerformanceMetricDropdown>
                      <Dropdown.Item onClick={() => setShowApdexScoreExplanationModal(true)}>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth className="text-secondary" />
                        What is an Apdex score?
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate(`/apps?editApp=${activeApp?.id}`)}>
                        <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                        Configure threshold
                      </Dropdown.Item>
                    </PerformanceMetricDropdown>
                  </div>
                  <div className="mt-3">
                    <ApdexScoreLineChart
                      app={app}
                      env={env}
                      period={period}
                      displayTitle={false}
                      style={{ height: "200px" }}
                    />
                  </div>
                </Col>
                <Col sm={12} lg={6} className="metric-item">
                  <div className="d-flex">
                    <Metric
                      label={`Response time (${responseTimeMetricPercentile})`}
                      value={
                        metricsQuery.data?.total_request_count && responseTimeMetric
                          ? formatResponseTime(responseTimeMetric)
                          : "-"
                      }
                      description="Across all endpoints"
                    />
                    <PerformanceMetricDropdown>
                      <Dropdown.Header>Switch percentile</Dropdown.Header>
                      {["p50", "p75", "p95"].map((percentile) => (
                        <Dropdown.Item
                          key={percentile}
                          active={responseTimeMetricPercentile === percentile}
                          onClick={() => setResponseTimeMetricPercentile(percentile)}
                        >
                          {percentile}
                        </Dropdown.Item>
                      ))}
                    </PerformanceMetricDropdown>
                  </div>
                  <div className="mt-3">
                    <ResponseTimeLineChart
                      app={app}
                      env={env}
                      period={period}
                      displayTitle={false}
                      style={{ height: "200px" }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <PerformanceEndpointsTable app={app} env={env} period={period} />
      </div>
    </MainLayout>
  );
}

export default Performance;
