import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, CategoryScale, Chart as ChartJS, Title, Tooltip, TooltipItem } from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(BarController, BarElement, CategoryScale, Title, Tooltip);

type RequestsByConsumerBarChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  endpoint: Endpoint;
  consumerGroupId?: number;
  period: string;
};

function RequestsByConsumerBarChart({ app, env, endpoint, consumerGroupId, period }: RequestsByConsumerBarChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerGroupId,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["requestsByConsumerChart", queryParams],
    queryFn: () => backendClient!.traffic.getRequestsByConsumerChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const dangerColor = getColor("danger");
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      indexAxis: "y" as const,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
            },
            color: "#adb5bd",
          },
          display: true,
          beginAtZero: true,
          precision: 0,
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
              weight: "bold" as const,
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          callbacks: {
            label: function (context: TooltipItem<"bar">) {
              const value = context.parsed.x.toLocaleString();
              return ` ${context.dataset.label}: ${value}`;
            },
          },
          filter: (context: TooltipItem<"bar">) => {
            return context.parsed.x > 0;
          },
          itemSort: (contextA: TooltipItem<"bar">, contextB: TooltipItem<"bar">) => {
            return contextB.parsed.x - contextA.parsed.x;
          },
        },
        legend: {
          display: false,
        },
      },
    };
    const chartData = {
      datasets: query.data.map((dataset) => ({
        label: dataset.response_status,
        data: dataset.consumer_names.slice(0, 10).map((consumer_name, index) => ({
          y: consumer_name,
          x: dataset.request_counts[index],
        })),
        backgroundColor: dataset.response_status === "Successful" ? primaryColor : dangerColor,
        hoverBackgroundColor: dataset.response_status === "Successful" ? primaryColor : dangerColor,
      })),
    };
    if (chartData.datasets.every((dataset) => dataset.data.length === 0)) {
      return <div className="text-center py-2">No requests by identified consumers in the selected period.</div>;
    } else {
      const uniqueConsumerCount = new Set(query.data?.flatMap((item) => item.consumer_names)).size;
      const chartHeight = 40 + Math.min(uniqueConsumerCount, 10) * 40;
      return (
        <div style={{ height: chartHeight }}>
          <Bar data={chartData} options={chartOptions} />
        </div>
      );
    }
  } else {
    return <></>;
  }
}

export default memo(RequestsByConsumerBarChart);
