import {
  faFaceAngry,
  faFaceFrownOpen,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "./Tooltip";

type ApdexScoreFaceProps = {
  apdexScore?: number;
  className?: string;
};

function ApdexScoreFace({ apdexScore, className }: ApdexScoreFaceProps) {
  if (apdexScore === undefined) {
    return null;
  }

  let tooltip;
  let iconProps;
  if (apdexScore >= 0.93) {
    tooltip = "Excellent";
    iconProps = { icon: faFaceGrinStars, className: "text-primary" };
  } else if (apdexScore >= 0.85) {
    tooltip = "Good";
    iconProps = { icon: faFaceSmile, className: "text-primary" };
  } else if (apdexScore >= 0.7) {
    tooltip = "Fair";
    iconProps = { icon: faFaceMeh, className: "text-secondary" };
  } else if (apdexScore >= 0.5) {
    tooltip = "Poor";
    iconProps = { icon: faFaceFrownOpen, className: "text-danger" };
  } else {
    tooltip = "Unacceptable";
    iconProps = { icon: faFaceAngry, className: "text-danger" };
  }

  return (
    <span className={`ApdexScoreFace ${className || ""}`.trim()}>
      <Tooltip tooltip={tooltip} placement="right">
        <FontAwesomeIcon {...iconProps} />
      </Tooltip>
    </span>
  );
}

export default ApdexScoreFace;
