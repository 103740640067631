import {
  faAngleRight,
  faBan,
  faChartArea,
  faChartColumn,
  faChartSimple,
  faCircleExclamation,
  faCog,
  faEye,
  faEyeSlash,
  faFilter,
  faRotateRight,
  faTriangleExclamation,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Stack from "react-bootstrap/esm/Stack";
import { Link, Navigate, useNavigate } from "react-router-dom";
import semver from "semver";

import "./Traffic.scss";
import { AppEnvItem, ListAppsResponseItem, TrafficEndpointsTableItem } from "./backend";
import DataTransferredBarChart from "./charts/DataTransferredBarChart";
import ErrorRatesLineChart from "./charts/ErrorRatesLineChart";
import RequestsBarChart from "./charts/RequestsBarChart";
import ResponseTimeLineChart from "./charts/ResponseTimeLineChart";
import ActionsDropdown from "./components/ActionsDropdown";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomIcon from "./components/CustomIcon";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import Metric from "./components/Metric";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useGlobal } from "./contexts/GlobalContext";
import useDashboardFilters from "./hooks/useDashboardFilters";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import EndpointConfigModal from "./modals/EndpointConfigModal";
import EndpointModal from "./modals/EndpointModal";
import TrafficFilterModal from "./modals/TrafficFilterModal";
import { Endpoint } from "./types/Endpoint";
import { formatDataSize, formatResponseTime } from "./utils/numbers";
import { isCustomPeriod } from "./utils/period";

type TrafficMetricNavItemProps = {
  eventKey: string;
  label: string;
  value?: React.ReactNode;
  description?: string;
  icon: IconDefinition;
};

function TrafficMetricNavItem({ eventKey, label, value, description, icon }: TrafficMetricNavItemProps) {
  return (
    <Nav.Item as={Col} sm={6} lg={3} className="TrafficMetricNavItem">
      <Nav.Link eventKey={eventKey}>
        <Stack direction="horizontal" className="align-items-start">
          <Metric label={label} value={value} description={description} />
          <div className="ms-auto">
            <div className="icon-circle">
              <FontAwesomeIcon icon={icon} />
            </div>
          </div>
        </Stack>
      </Nav.Link>
    </Nav.Item>
  );
}

type TrafficEndpointsTableProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpointGroupId?: number;
  period: string;
};

function TrafficEndpointsTable({
  app,
  env,
  consumerId,
  consumerGroupId,
  endpointGroupId,
  period,
}: TrafficEndpointsTableProps) {
  const { backendClient, isTeamAdmin, isDemo, timezone } = useGlobal();
  const [showExcluded, setShowExcluded] = useState(false);
  const [modalEndpoint, setModalEndpoint] = useState<Endpoint>();
  const [endpointConfigModalEndpoint, setEndpointConfigModalEndpoint] = useState<Endpoint>();
  const [sortedData, setSortedData] = useState<TrafficEndpointsTableItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<TrafficEndpointsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "requests", direction: "desc" });
  const queryClient = useQueryClient();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["trafficEndpointsTable", queryParams],
    queryFn: () => backendClient!.traffic.getTrafficEndpointsTable(queryParams),
    enabled: !!backendClient,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const includedData = query.data?.filter((item) => !item.excluded);
  const maxRequestCount = includedData?.reduce((max, item) => Math.max(max, item.total_request_count), 0);
  const maxErrorRate = includedData?.reduce((max, item) => Math.max(max, item.error_rate), 0);
  const maxResponseTime = includedData?.reduce((max, item) => Math.max(max, item.response_time_p95), 0);
  const maxDataTransferred = includedData?.reduce((max, item) => Math.max(max, item.data_transferred), 0);
  const noDataReceivedYet = query.isSuccess && query.data.length === 0;
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) => searchTerms.every((term) => item.path.toLowerCase().includes(term))),
      );
    } else {
      setSearchResult(sortedData);
    }
  }, [search, sortedData]);

  useEffect(() => {
    setSearch("");
  }, [app]);

  const sortData = (data: TrafficEndpointsTableItem[], sortBy: SortBy) => {
    const sort = (a: TrafficEndpointsTableItem, b: TrafficEndpointsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.excluded && !b.excluded) {
        return 1;
      } else if (!a.excluded && b.excluded) {
        return -1;
      }

      if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      }

      if (a.total_request_count > 0 && b.total_request_count === 0) {
        return 1 * sign;
      } else if (a.total_request_count === 0 && b.total_request_count > 0) {
        return -1 * sign;
      }

      if (sortBy.column === "requests") {
        return (a.total_request_count - b.total_request_count) * sign;
      } else if (sortBy.column === "error_rate") {
        return (a.error_rate - b.error_rate) * sign;
      } else if (sortBy.column === "response_time") {
        return (a.response_time_p95 - b.response_time_p95) * sign;
      } else if (sortBy.column === "data_transferred") {
        return (a.data_transferred - b.data_transferred) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const excludedItems = searchResult?.filter((item) => item.excluded);
  const tableFooter =
    excludedItems && excludedItems.length > 0 ? (
      <div className="small">
        {!showExcluded ? (
          <Button variant="link" onClick={() => setShowExcluded(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show {excludedItems.length} excluded endpoint
            {excludedItems.length > 1 ? "s" : ""}
          </Button>
        ) : (
          <Button variant="link" onClick={() => setShowExcluded(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide {excludedItems.length} excluded endpoint
            {excludedItems.length > 1 ? "s" : ""}
          </Button>
        )}
      </div>
    ) : undefined;
  const searchHeader =
    query.isSuccess && query.data.length > 10 ? (
      <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search endpoints" />
    ) : undefined;

  return (
    <div className="TrafficEndpointsTable">
      <EndpointModal
        app={app}
        env={env}
        consumerId={consumerId}
        consumerGroupId={consumerGroupId}
        period={period}
        endpoint={modalEndpoint}
        setEndpoint={setModalEndpoint}
      />
      <EndpointConfigModal
        app={app}
        endpoint={endpointConfigModalEndpoint}
        setEndpoint={setEndpointConfigModalEndpoint}
        refresh={refresh}
      />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ minWidth: "40px" }}></th>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy}>
              Requests
            </ColumnHeader>
            <ColumnHeader name="error_rate" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              Error rate
            </ColumnHeader>
            <ColumnHeader name="response_time" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              Response time
            </ColumnHeader>
            <ColumnHeader name="data_transferred" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              Data transferred
            </ColumnHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter((item) => !item.excluded || showExcluded)
            .map((item) => (
              <tr
                key={`${item.method} ${item.path}`}
                className="cursor-pointer"
                onClick={() =>
                  setModalEndpoint({ id: item.id, method: item.method, path: item.path, excluded: item.excluded })
                }
              >
                <td width={40}>
                  <FontAwesomeIcon icon={item.excluded ? faBan : faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  showBar={!item.excluded && (sortBy.column === "requests" || sortBy.column === "endpoint")}
                  value={item.total_request_count}
                  maxValue={maxRequestCount}
                >
                  {item.total_request_count.toLocaleString()}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "error_rate"}
                  value={item.error_rate}
                  maxValue={maxErrorRate}
                >
                  {item.total_request_count > 0
                    ? item.error_rate
                        .toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                        .replace("%", " %")
                    : "-"}
                  {item.server_error_count > 0 ? (
                    <Tooltip
                      tooltip={`${item.server_error_count} server error${item.server_error_count > 1 ? "s" : ""}`}
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} className="ms-2 text-danger" />
                    </Tooltip>
                  ) : undefined}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time"}
                  value={item.response_time_p95}
                  maxValue={maxResponseTime}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p95) : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "data_transferred"}
                  value={item.data_transferred}
                  maxValue={maxDataTransferred}
                >
                  {formatDataSize(item.data_transferred)}
                </TableCellWithBar>
                <td className="text-end align-middle pe-4">
                  {isTeamAdminOrDemo && (
                    <ActionsDropdown>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setModalEndpoint({
                            id: item.id,
                            method: item.method,
                            path: item.path,
                            excluded: item.excluded,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                        Endpoint insights
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setEndpointConfigModalEndpoint({
                            id: item.id,
                            method: item.method,
                            path: item.path,
                            excluded: item.excluded,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                        Endpoint settings
                      </Dropdown.Item>
                    </ActionsDropdown>
                  )}
                </td>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td width={40}></td>
              <td colSpan={5} className="text-center py-6">
                Your search didn't match any endpoints.
              </td>
              <td width={40}></td>
            </tr>
          )}
          {query.isPending &&
            [...Array(5)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td width={40}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    className="me-4"
                    style={{ width: "3.5rem", borderRadius: "0.375rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={6} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td></td>
              </Placeholder>
            ))}
          {noDataReceivedYet && (
            <tr>
              <td colSpan={7} className="text-center py-6">
                <div>
                  We haven't received any data from this {env ? "environment" : "application"} yet.
                  <br />
                  It could take up to 1 minute to show up.
                </div>
                <div className="mt-4">
                  <Button variant="primary" onClick={refresh} disabled={query.isFetching}>
                    Try again now
                  </Button>
                </div>
                <div className="mt-4 small text-muted">
                  Want to see the <Link to={`/apps/${app.slug}/setup`}>setup instructions</Link> again?
                  <br />
                  Still no luck? Please{" "}
                  <a
                    href={`mailto:support@apitally.io?subject=${encodeURIComponent(
                      `I'm having trouble setting up my ${app.framework} app`,
                    )}`}
                  >
                    send us an email
                  </a>
                  . We're here to help.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </TableCard>
    </div>
  );
}

function TrafficTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="TrafficTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Traffic</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faChartSimple} iconStyle={{ right: "-40px" }}>
          <h2>Easily understand your API, its usage and consumers</h2>
          <p className="mt-4">
            Get actionable usage and performance insights about your API, each endpoint and individual API consumers,
            allowing you to make informed, data-driven engineering and product decisions.
          </p>
          <ul className="my-4">
            <li>
              <b>Requests:</b> Track number of requests over time, per endpoint and per consumer
            </li>
            <li>
              <b>Error rates:</b> Monitor client & server errors, get a breakdown of returned status codes
            </li>
            <li>
              <b>Response times:</b> Understand the performance of each endpoint and identify bottlenecks
            </li>
            <li>
              <b>Payload sizes:</b> Keep track of request & response sizes and total data transferred
            </li>
          </ul>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Traffic() {
  const { apps, activeApp, backendClient, timezone } = useGlobal();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const {
    app,
    appSlug,
    env,
    setEnv,
    period,
    setPeriod,
    resetPeriod,
    consumerId,
    setConsumerId,
    consumerGroupId,
    setConsumerGroupId,
    endpointGroupId,
    setEndpointGroupId,
  } = useDashboardFilters();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  const consumersQueryParams = { appId: app?.id || 0, appEnv: env?.slug };
  const consumersQuery = useQuery({
    queryKey: ["consumers", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumers(consumersQueryParams),
    enabled: !!backendClient && !!app,
  });
  const consumerGroupsQuery = useQuery({
    queryKey: ["consumerGroups", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroups(consumersQueryParams),
    enabled: !!backendClient && !!app,
  });
  const endpointGroupsQueryParams = { appId: app?.id || 0, appEnvId: env?.id };
  const endpointGroupsQuery = useQuery({
    queryKey: ["endpointGroups", endpointGroupsQueryParams],
    queryFn: () => backendClient!.endpoints.listEndpointGroups(endpointGroupsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
    timezone,
  };
  const metricsQuery = useQuery({
    queryKey: ["trafficMetrics", metricsQueryParams],
    queryFn: () => backendClient!.traffic.getTrafficMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  useEffect(() => {
    if (app) {
      document.title = `Traffic - ${app.name} - Apitally`;
    } else {
      document.title = `Traffic - Apitally`;
    }
  }, [app]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <TrafficTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/traffic/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/traffic/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const showUpgradeClientAlert =
    metricsQuery.isSuccess &&
    !metricsQuery.data.total_data_transferred &&
    env?.versions &&
    env.versions.apitally &&
    ((env.client?.startsWith("python:") && semver.lt(env.versions.apitally, "0.4.0")) ||
      (env.client?.startsWith("js:") && semver.lt(env.versions.apitally, "0.2.0")));

  const filterCount = (consumerId ? 1 : 0) + (consumerGroupId ? 1 : 0) + (endpointGroupId ? 1 : 0);
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <Button key="filter-button" variant="outline-light" onClick={() => setShowFilterModal(true)}>
      <CustomIcon src="/icons/filter-regular.svg" className="me-icon text-secondary" />
      Filter{" "}
      {filterCount > 0 && (
        <Badge pill bg="secondary" className="ms-1">
          {filterCount}
        </Badge>
      )}
    </Button>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => setShowFilterModal(true)}>
        <FontAwesomeIcon icon={faFilter} fixedWidth />
        Filter
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Traffic">
        <TrafficFilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          consumers={consumersQuery.data}
          consumerGroups={consumerGroupsQuery.data}
          endpointGroups={endpointGroupsQuery.data}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          endpointGroupId={endpointGroupId}
          setConsumerId={setConsumerId}
          setConsumerGroupId={setConsumerGroupId}
          setEndpointGroupId={setEndpointGroupId}
        />
        <CustomPeriodModal
          show={showCustomPeriodModal}
          setShow={setShowCustomPeriodModal}
          period={period}
          setPeriod={setPeriod}
        />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Traffic</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/traffic/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges
          period={period}
          resetPeriod={() => resetPeriod()}
          env={env?.slug}
          clearEnv={() => setEnv(undefined)}
          consumer={consumersQuery.data?.find((consumer) => consumer.id === consumerId)?.name}
          clearConsumer={() => setConsumerId(undefined)}
          consumerGroup={consumerGroupsQuery.data?.find((consumerGroup) => consumerGroup.id === consumerGroupId)?.name}
          clearConsumerGroup={() => setConsumerGroupId(undefined)}
          endpointGroup={endpointGroupsQuery.data?.find((endpointGroup) => endpointGroup.id === endpointGroupId)?.name}
          clearEndpointGroup={() => setEndpointGroupId(undefined)}
        />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="p-0">
            <Tab.Container defaultActiveKey="requests">
              <Nav>
                <Container fluid>
                  <Row>
                    <TrafficMetricNavItem
                      eventKey="requests"
                      label="Requests"
                      value={metricsQuery.data?.total_request_count.toLocaleString()}
                      icon={faChartColumn}
                    />
                    <TrafficMetricNavItem
                      eventKey="error-rate"
                      label="Error rate"
                      value={
                        (metricsQuery.data?.total_request_count ?? 0 > 0)
                          ? metricsQuery.data?.error_rate
                              .toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                              .replace("%", " %")
                          : "-"
                      }
                      description="Percentage of unsuccessful requests"
                      icon={faChartArea}
                    />
                    <TrafficMetricNavItem
                      eventKey="response-time"
                      label="Response time (p95)"
                      value={
                        metricsQuery.data?.total_request_count
                          ? formatResponseTime(metricsQuery.data.response_time_p95)
                          : "-"
                      }
                      description="Approximate 95th percentile of response times"
                      icon={faChartArea}
                    />
                    <TrafficMetricNavItem
                      eventKey="data-transferred"
                      label="Data transferred"
                      value={
                        metricsQuery.isSuccess ? (
                          <>
                            {formatDataSize(metricsQuery.data.total_data_transferred)}
                            {showUpgradeClientAlert && (
                              <Tooltip
                                tooltip="Please update the Apitally client library in your project to capture request and response payload sizes."
                                placement="bottom"
                              >
                                <FontAwesomeIcon icon={faCircleExclamation} className="ms-2 small text-danger" />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          "-"
                        )
                      }
                      description="Sum of the request and response payload sizes"
                      icon={faChartColumn}
                    />
                  </Row>
                  <Row>
                    <Col sm={12} className="py-2 text-center chart">
                      <Tab.Content>
                        <Tab.Pane eventKey="requests">
                          <RequestsBarChart
                            app={app}
                            env={env}
                            consumerId={consumerId}
                            consumerGroupId={consumerGroupId}
                            endpointGroupId={endpointGroupId}
                            period={period}
                            setPeriod={setPeriod}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="error-rate">
                          <ErrorRatesLineChart
                            app={app}
                            env={env}
                            consumerId={consumerId}
                            consumerGroupId={consumerGroupId}
                            endpointGroupId={endpointGroupId}
                            period={period}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="response-time">
                          <ResponseTimeLineChart
                            app={app}
                            env={env}
                            consumerId={consumerId}
                            consumerGroupId={consumerGroupId}
                            endpointGroupId={endpointGroupId}
                            period={period}
                            displayTargetLine={false}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="data-transferred">
                          <DataTransferredBarChart
                            app={app}
                            env={env}
                            consumerId={consumerId}
                            consumerGroupId={consumerGroupId}
                            endpointGroupId={endpointGroupId}
                            period={period}
                            setPeriod={setPeriod}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Container>
              </Nav>
            </Tab.Container>
          </Card.Body>
        </Card>
        <TrafficEndpointsTable
          app={app}
          env={env}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          endpointGroupId={endpointGroupId}
          period={period}
        />
      </div>
    </MainLayout>
  );
}

export default Traffic;
