import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import "./TableCardSearchHeader.css";

type TableCardSearchHeaderProps = {
  search: string;
  setSearch: (search: string) => void;
  placeholder?: string;
  paddingLeft?: number | string;
};

function TableCardSearchHeader({ search, setSearch, placeholder, paddingLeft = 48 }: TableCardSearchHeaderProps) {
  const [searchInputValue, setSearchInputValue] = useState(search);
  const debouncedSetSearch = useMemo(() => debounce(setSearch, 250), [setSearch]);

  useEffect(() => {
    debouncedSetSearch(searchInputValue);
  }, [searchInputValue]);

  useEffect(() => {
    setSearchInputValue(search);
  }, [search]);

  return (
    <div className="TableCardSearchHeader">
      <FontAwesomeIcon icon={faSearch} className="search-input-icon" />
      <Form.Control
        name="search"
        placeholder={placeholder}
        className="search-input"
        value={searchInputValue}
        onChange={(e) => setSearchInputValue(e.target.value)}
        style={{ paddingLeft }}
      />
      <a
        className="search-reset-button"
        role="button"
        title="Clear search"
        hidden={search === ""}
        onClick={() => setSearchInputValue("")}
      >
        <FontAwesomeIcon icon={faXmark} />
      </a>
    </div>
  );
}

export default TableCardSearchHeader;
