type SteppedProgressBarProps = {
  steps: number;
  currentStep: number;
  className?: string;
};

function SteppedProgressBar({ steps, currentStep, className }: SteppedProgressBarProps) {
  const stepStyle = (index: number) => ({
    height: "0.5rem",
    backgroundColor: index < currentStep ? "var(--bs-primary)" : "var(--bs-gray-300)",
  });

  return (
    <div className={`d-flex gap-1 ${className || ""}`}>
      {Array.from({ length: steps }, (_, index) => (
        <div key={index} className="flex-fill rounded-pill" style={stepStyle(index)}></div>
      ))}
    </div>
  );
}

export default SteppedProgressBar;
