import { ReactSVG } from "react-svg";

import "./CustomIcon.css";

type CustomIconProps = {
  src: string;
  className?: string;
  fixedWidth?: boolean;
};

function CustomIcon({ src, className, fixedWidth = false }: CustomIconProps) {
  return <ReactSVG src={src} className={`CustomIcon ${fixedWidth ? "fw" : ""} ${className || ""}`} wrapper="span" />;
}

export default CustomIcon;
