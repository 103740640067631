import { useQuery } from "@tanstack/react-query";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { getReasonPhrase } from "http-status-codes";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint, EndpointError } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type ErrorsBarChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpoint?: Endpoint;
  endpointError?: EndpointError;
  period: string;
  displayTitle?: boolean;
};

function ErrorsBarChart({
  app,
  env,
  consumerId,
  consumerGroupId,
  endpoint,
  endpointError,
  period,
  displayTitle = true,
}: ErrorsBarChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    method: endpointError?.method || endpoint?.method,
    path: endpointError?.path || endpoint?.path,
    statusCode: endpointError?.status_code,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["errorsChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorsChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess && query.data.length > 0) {
    const dangerColor = getColor("danger");
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? "Number of unsuccessful requests" : undefined,
      }),
      {
        scales: {
          x: { stacked: true },
          y: { stacked: true, ticks: { precision: 0 } },
        },
        plugins: {
          tooltip: {
            filter: (context: TooltipItem<"bar">) => {
              return context.parsed.y > 0;
            },
            itemSort: (context: TooltipItem<"bar">) => {
              return context.dataset.label === "Server error" ? 1 : -1;
            },
            callbacks: {
              label: (context: TooltipItem<"bar">) => {
                const statusCodeCounts = (context.dataset as any).statusCodeCounts[context.dataIndex] as number[][];
                return statusCodeCounts.map(([statusCode, count]) => {
                  return ` ${statusCode} ${getReasonPhrase(statusCode)}: ${count.toLocaleString()}`;
                });
              },
            },
          },
        },
      },
    );
    const chartData = {
      labels: query.data[0].time_windows,
      datasets: query.data.map((dataset) => ({
        label: dataset.error_type,
        data: dataset.request_counts,
        statusCodeCounts: dataset.status_code_counts,
        backgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dea6a6",
        hoverBackgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dea6a6",
      })),
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ErrorsBarChart);
