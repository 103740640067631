import isEqual from "lodash/isEqual";

import { ApiError } from "../backend";

export function getBackendValidationErrorMessage(error: ApiError, loc: string[]) {
  const detail = (error.body as any)?.detail;
  if (detail && Array.isArray(detail)) {
    return detail.find((detail: any) => isEqual(detail.loc, loc))?.msg;
  }
}
