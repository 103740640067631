import { ReactElement } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { CodeBlockProps } from "./CodeBlock";
import "./CodeBlockGroup.css";

type CodeBlockGroupProps = {
  children: ReactElement<CodeBlockProps>[] | ReactElement<CodeBlockProps>;
};

function CodeBlockGroup({ children }: CodeBlockGroupProps) {
  if (!Array.isArray(children)) {
    return children;
  } else if (children.length === 0) {
    return null;
  }
  return (
    <div className="CodeBlockGroup">
      <Tab.Container defaultActiveKey={children[0].props.title}>
        <Nav variant="underline">
          {children.map((codeBlock) => (
            <Nav.Item key={codeBlock.props.title} className="small fw-bold">
              <Nav.Link eventKey={codeBlock.props.title}>{codeBlock.props.title}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content>
          {children.map((codeBlock) => (
            <Tab.Pane key={codeBlock.props.title} eventKey={codeBlock.props.title}>
              {codeBlock}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default CodeBlockGroup;
