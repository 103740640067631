import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

import { useGlobal } from "../contexts/GlobalContext";
import "./TeaserCard.scss";

type TeaserCardProps = {
  children: React.ReactNode;
  icon?: IconDefinition;
  iconStyle?: React.CSSProperties;
};

function TeaserCard({ children, icon, iconStyle }: TeaserCardProps) {
  const { launchDemo } = useGlobal();

  return (
    <Card className="TeaserCard bt">
      <Card.Body className="p-0">
        <Container fluid className="p-0">
          <Row className="m-0">
            <Col lg={9} className="p-6">
              {children}
              <p className="mt-4 mb-0">
                <Link to="/apps?createApp">Set up your app</Link> to get started or{" "}
                <Button variant="link" onClick={() => launchDemo()}>
                  explore with demo data
                </Button>
                .
              </p>
            </Col>
            <Col lg={3} className="d-none d-lg-block p-0">
              {icon && (
                <div className="d-flex h-100 justify-content-end align-items-end overflow-hidden rounded">
                  <FontAwesomeIcon icon={icon} className="large-icon" style={iconStyle} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default TeaserCard;
