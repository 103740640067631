import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import { useGlobal } from "../contexts/GlobalContext";

function TeamDropdown() {
  const { teams, activeTeam, setActiveTeam } = useGlobal();

  if (teams !== undefined && teams.length === 0 && !activeTeam) {
    return <span className="text-muted">No teams set up yet</span>;
  }

  return (
    <Dropdown onSelect={(key) => key && teams && setActiveTeam(teams.find((team) => team.slug === key))}>
      <Dropdown.Toggle as="a">{activeTeam?.name}</Dropdown.Toggle>
      <Dropdown.Menu className="lh-base">
        <Dropdown.Header>Switch team</Dropdown.Header>
        {teams?.map((team) => (
          <Dropdown.Item key={team.id} eventKey={team.slug} active={team.id === activeTeam?.id}>
            {team.name}
            {team.demo && (
              <Badge
                pill
                bg={team === activeTeam ? "light" : "primary"}
                className={team === activeTeam ? "outline-light ms-2" : "outline-primary ms-2"}
              >
                Demo
              </Badge>
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TeamDropdown;
