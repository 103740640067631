import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ListAlertsResponseItem } from "../backend";
import AppEnvBadge from "../components/AppEnvBadge";
import DetailsModalContent from "../components/DetailsModalContent";
import { formatDateTimeDiff } from "../utils/datetime";

type AlertDetailsModalProps = {
  alert?: ListAlertsResponseItem;
  setAlert: React.Dispatch<React.SetStateAction<ListAlertsResponseItem | undefined>>;
};

function AlertDetailsModal({ alert, setAlert }: AlertDetailsModalProps) {
  let status = <></>;
  if (alert) {
    if (alert.ignored && !alert.resolved) {
      status = <span className="text-secondary">Active (ignored)</span>;
    } else if (!alert.resolved) {
      status = <span className="text-danger">Active</span>;
    } else if (!alert.archived) {
      status = <span className="text-primary">Resolved</span>;
    } else if (alert.archived) {
      status = <span className="text-secondary">Archived</span>;
    }
  }

  const envBadge = <AppEnvBadge env={alert?.app_env} className="me-1 cursor-default" />;
  const createdAt = alert ? DateTime.fromISO(alert.created_at) : undefined;
  const resolvedAt = alert?.resolved_at ? DateTime.fromISO(alert.resolved_at) : undefined;
  const archivedAt = alert?.archived_at ? DateTime.fromISO(alert.archived_at) : undefined;

  return (
    <Modal size="lg" show={alert !== undefined} onHide={() => setAlert(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Alert details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "Alert name": alert?.name,
            "App": alert?.app.name,
            "Environment": envBadge,
            "Status": status,
            "Triggered at": createdAt?.toLocaleString(DateTime.DATETIME_FULL),
            "Resolved at": resolvedAt?.toLocaleString(DateTime.DATETIME_FULL),
            "Archived at": archivedAt?.toLocaleString(DateTime.DATETIME_FULL),
            "Active for": formatDateTimeDiff(createdAt, resolvedAt),
          }}
          modalSize="lg"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAlert(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertDetailsModal;
