import React from "react";
import Modal from "react-bootstrap/Modal";

import ApdexFormula from "../static/apdex-formula.svg?react";

type ApdexScoreExplanationModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

function ApdexScoreExplanationModal({ show, setShow }: ApdexScoreExplanationModalProps) {
  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>What is an Apdex score?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          An Apdex score is an open industry standard to measure the user satisfaction with an application's response
          times.
        </p>
        <p>The score is based on a response time threshold (T) and four counts:</p>
        <ul>
          <li>
            <b>Satisfied:</b> Response time is less than or equal to T.
          </li>
          <li>
            <b>Tolerating:</b> Response time is greater than T but less than or equal to 4T.
          </li>
          <li>
            <b>Frustrated:</b> Response time is greater than 4T.
          </li>
          <li>
            <b>Total:</b> Total number of requests.
          </li>
        </ul>
        <p>The formula to calculate the Apdex score is:</p>
        <p style={{ textAlign: "center" }}>
          {/* $$\frac{\text{Satisfied} + 0.5 \cdot \text{Tolerating} + 0 \cdot \text{Frustrated}}{\text{Total}}$$ */}
          <ApdexFormula className="mw-100" />
        </p>
        <p>
          Apdex scores range from 0 to 1, with 0 meaning that users were frustrated with all response times, and 1
          meaning that users were satisfied with all response times.
        </p>
        <p className="mb-0">
          In Apitally, you can configure a global response time threshold (T) for your application and override it for
          individual endpoints.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ApdexScoreExplanationModal;
