import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./Metric.css";
import Tooltip from "./Tooltip";

type MetricProps = {
  label: string;
  value?: React.ReactNode;
  description?: string;
};

function Metric({ label, value, description }: MetricProps) {
  const descriptionIcon = (
    <>
      {description && (
        <Tooltip placement="bottom" tooltip={description}>
          <small>
            <FontAwesomeIcon icon={faCircleQuestion} className="ms-2 text-body-tertiary" />
          </small>
        </Tooltip>
      )}
    </>
  );

  return (
    <div className="Metric">
      <div className="metric-label">
        {label}
        {descriptionIcon}
      </div>
      <div className="metric-value">{value || "-"}</div>
    </div>
  );
}

export default Metric;
