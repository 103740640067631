import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

type ModalSectionProps = {
  title: React.ReactNode;
  titleExtra?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

function ModalSection({ title, titleExtra, className, children }: ModalSectionProps) {
  const [open, setOpen] = useState(true);

  return (
    <div className={`ModalSection ${className}`}>
      <div>
        <a role="button" className="fw-bold text-dark" onClick={() => setOpen((open) => !open)}>
          <FontAwesomeIcon
            icon={faCaretRight}
            rotation={open ? 90 : undefined}
            className="me-2 text-very-muted"
            style={{ transition: "transform 0.3s ease" }}
          />
          {title}
          {titleExtra && <span className="ms-2 small text-muted fw-normal d-none d-sm-inline">({titleExtra})</span>}
        </a>
      </div>
      <Collapse in={open}>
        <div style={{ marginTop: "0.75rem" }}>{children}</div>
      </Collapse>
    </div>
  );
}

export default ModalSection;
