import { useFormikContext } from "formik";
import { useState } from "react";
import ReactSelect, { GroupBase, OnChangeValue } from "react-select";
import CreatableReactSelect, { CreatableProps } from "react-select/creatable";

import { Option, OptionGroup, getSelectStyles } from "./Select";

declare type BaseProps = CreatableProps<Option, true, OptionGroup>;
type MultiSelectProps = {
  name: string;
  value: number[];
  keepInputOnSelect?: boolean;
} & Omit<BaseProps, "value" | "onChange" | "onBlur" | "isMulti">;

function MultiSelect({ name, value, keepInputOnSelect, options, onCreateOption, ...props }: MultiSelectProps) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [inputValue, setInputValue] = useState("");

  const flattenedOptions = options?.flatMap((o) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });
  const selectedOptions = flattenedOptions?.filter((option) => value.includes(option.value));
  const onChange = (selectedOption: OnChangeValue<Option, true>) => {
    setFieldValue(
      name,
      selectedOption.map((option) => option.value),
    );
  };
  const onBlur = () => {
    setFieldTouched(name, true);
  };
  const { styles, theme } = getSelectStyles<Option, true, GroupBase<Option>>();
  const extraProps: Partial<BaseProps> = keepInputOnSelect
    ? {
        inputValue,
        onInputChange: (value, action) => {
          if (action.action === "input-change") {
            setInputValue(value);
          }
        },
      }
    : {};

  if (onCreateOption) {
    return (
      <CreatableReactSelect
        isMulti
        options={options}
        value={selectedOptions}
        onChange={onChange}
        onBlur={onBlur}
        onCreateOption={onCreateOption}
        onInputChange={(inputValue) => inputValue}
        styles={styles}
        theme={theme}
        {...props}
        {...extraProps}
      />
    );
  } else {
    return (
      <ReactSelect
        isMulti
        options={options}
        value={selectedOptions}
        onChange={onChange}
        onBlur={onBlur}
        styles={styles}
        theme={theme}
        {...props}
        {...extraProps}
      />
    );
  }
}

export default MultiSelect;
export type { Option, OptionGroup };
