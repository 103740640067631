import { useQuery } from "@tanstack/react-query";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type RequestsBarChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpointGroupId?: number;
  endpoint?: Endpoint;
  period: string;
  setPeriod?: (period: string) => void;
  displayTitle?: boolean;
};

function RequestsBarChart({
  app,
  env,
  consumerId,
  consumerGroupId,
  endpointGroupId,
  endpoint,
  period,
  setPeriod,
  displayTitle = true,
}: RequestsBarChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["requestsChart", queryParams],
    queryFn: () => backendClient!.traffic.getRequestsChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess && query.data.length > 0) {
    const primaryColor = getColor("primary");
    const secondaryColor = getColor("secondary");
    const dangerColor = getColor("danger");
    const colorMap = new Map([
      ["Successful", primaryColor],
      ["Unsuccessful", dangerColor],
    ]);
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? "Number of requests" : undefined,
        setPeriod,
      }),
      {
        scales: { x: { stacked: true }, y: { stacked: true, ticks: { precision: 0 } } },
        plugins: {
          tooltip: {
            filter: (context: TooltipItem<"bar">) => {
              return context.parsed.y > 0;
            },
            itemSort: (contextA: TooltipItem<"bar">, contextB: TooltipItem<"bar">) => {
              return contextB.parsed.y - contextA.parsed.y;
            },
          },
        },
      },
    );
    const chartData = {
      labels: query.data[0].time_windows,
      datasets: query.data.map((dataset) => {
        const color = colorMap.get(dataset.response_status) || secondaryColor;
        return {
          label: dataset.response_status,
          data: dataset.request_counts,
          backgroundColor: color,
          hoverBackgroundColor: rgba(color, 0.8),
        };
      }),
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(RequestsBarChart);
