import capitalize from "lodash/capitalize";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import { AppEnvItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import CustomDropdown from "./Dropdown";

type AppEnvDropdownItemsProps = {
  appEnv?: AppEnvItem;
  setAppEnv?: (appEnv?: AppEnvItem) => void;
};

export function AppEnvDropdownItems({ appEnv, setAppEnv }: AppEnvDropdownItemsProps) {
  const { apps, activeApp } = useGlobal();
  const envs = apps?.find((app) => app.id === activeApp?.id)?.envs;

  return (
    <>
      {envs?.map((env) => (
        <Dropdown.Item
          key={env.id}
          eventKey={env.slug}
          active={env.id === appEnv?.id}
          onClick={setAppEnv ? () => setAppEnv(env) : undefined}
        >
          {env.name}
          {!env.online && (
            <Badge
              pill
              bg={env.id === appEnv?.id ? "light" : "danger"}
              className={env.id === appEnv?.id ? "ms-2 outline-light" : "ms-2 outline-danger"}
            >
              Offline
            </Badge>
          )}
        </Dropdown.Item>
      ))}
    </>
  );
}

type AppEnvDropdownProps = {
  appEnv?: AppEnvItem;
  setAppEnv: (appEnv?: AppEnvItem) => void;
};

function AppEnvDropdown({ appEnv, setAppEnv }: AppEnvDropdownProps) {
  const { apps, activeApp } = useGlobal();
  const envs = apps?.find((app) => app.id === activeApp?.id)?.envs;

  return (
    <CustomDropdown
      title={capitalize(appEnv?.name) || "All envs"}
      icon="/icons/layer-group-regular.svg"
      onSelect={(key: string) => setAppEnv(envs?.find((env) => env.slug === key))}
    >
      <Dropdown.Menu className="lh-base">
        <Dropdown.Item eventKey="" active={!appEnv}>
          All envs
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Filter by environment</Dropdown.Header>
        <AppEnvDropdownItems appEnv={appEnv} />
      </Dropdown.Menu>
    </CustomDropdown>
  );
}

export default AppEnvDropdown;
