import classNames from "classnames";
import Badge from "react-bootstrap/Badge";

import "./StatusCode.css";

type StatusCodeBadgeProps = {
  code: number;
  text: React.ReactNode;
  className?: string;
};

function StatusCode({ code, text, className }: StatusCodeBadgeProps) {
  return (
    <div className="StatusCodeBadge">
      <Badge bg="danger" className={classNames({ "outline-danger": code < 500 }, className)}>
        {code}
      </Badge>
      <div>{text}</div>
    </div>
  );
}

export default StatusCode;
