import { IconDefinition, faChevronDown, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";

import CustomIcon from "./CustomIcon";

type DropdownProps = {
  title: React.ReactNode;
  icon?: IconDefinition | string;
  ellipsis?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
} & React.ComponentPropsWithoutRef<typeof BootstrapDropdown>;

function Dropdown({ title, icon, ellipsis = false, children, className, ...props }: DropdownProps) {
  const iconClassName = "me-2 text-secondary";
  const iconElement = icon ? (
    typeof icon === "string" ? (
      <CustomIcon src={icon} className={iconClassName} />
    ) : (
      <FontAwesomeIcon icon={icon as IconDefinition} className={iconClassName} />
    )
  ) : undefined;
  return (
    <BootstrapDropdown align="end" className={`no-caret ${className || ""}`} {...props}>
      <BootstrapDropdown.Toggle variant="outline-light">
        {iconElement}
        {title}
        {ellipsis ? (
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="me-0 text-very-muted"
            style={{ marginLeft: "0.625rem", position: "relative", bottom: "-1px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronDown}
            className="ms-2 me-0 text-very-muted very-small"
            style={{ position: "relative", bottom: "1px", right: "-1px" }}
          />
        )}
      </BootstrapDropdown.Toggle>
      {children}
    </BootstrapDropdown>
  );
}

export default Dropdown;
