import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type CopyToClipboardWithTooltipProps = {
  text: string;
  children: React.ReactElement;
  tooltipPlacement?: "left" | "right" | "top" | "bottom";
};

const CopyToClipboardWithTooltip = ({
  text,
  children,
  tooltipPlacement = "bottom",
}: CopyToClipboardWithTooltipProps) => {
  const [copied, setCopied] = useState(false);
  const tooltipText = copied ? "Copied!" : "Copy to clipboard";
  // Fixed position to avoid scrollbar jitter
  // See https://github.com/react-bootstrap/react-bootstrap/issues/6563
  const tooltip = (
    <Tooltip key={tooltipText} style={{ position: "fixed" }}>
      {tooltipText}
    </Tooltip>
  );

  const originalElem = React.Children.only(children);
  const elemWithHandler = React.cloneElement(originalElem, {
    onClick: () => {
      navigator.clipboard.writeText(text);
      setCopied(true);
    },
    onMouseLeave: () => {
      setTimeout(() => setCopied(false), 1000);
    },
  });

  return (
    <OverlayTrigger placement={tooltipPlacement} delay={{ show: 100, hide: 200 }} overlay={tooltip}>
      <span>{elemWithHandler}</span>
    </OverlayTrigger>
  );
};

export default CopyToClipboardWithTooltip;
