import { useQuery } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { useGlobal } from "../contexts/GlobalContext";

type TeamLeaveModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

interface TeamLeaveFormValues {
  name: string;
}

function TeamLeaveModal({ show, setShow }: TeamLeaveModalProps) {
  const { backendClient, userId, activeTeam, refreshTeams } = useGlobal();

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["team", queryParams],
    queryFn: () => backendClient!.teams.getTeam(queryParams),
    enabled: !!backendClient && !!activeTeam && show,
  });
  const leaveAllowed =
    query.isSuccess &&
    (query.data.demo || query.data.users.filter((user) => user.role === "owner" && user.id !== userId).length > 0);

  const validate = (values: TeamLeaveFormValues) => {
    const errors: Partial<TeamLeaveFormValues> = {};
    if (values.name.trim() !== activeTeam?.name) {
      errors.name = "Name does not match";
    }
    return errors;
  };

  const leaveTeam = async () => {
    if (backendClient && activeTeam) {
      const promise = backendClient.users.leaveTeam({ teamId: activeTeam.id });
      toast.promise(promise, {
        pending: "Leaving team...",
        success: "Left team!",
        error: "Failed to leave team.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        await leaveTeam();
        setSubmitting(false);
        setShow(false);
        refreshTeams();
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Leave team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {leaveAllowed && (
              <>
                <Alert variant="danger">
                  Are you sure you want to leave the team <strong>{activeTeam?.name}</strong>?<br />
                  You will lose access to all apps in this team.
                </Alert>
                <Form onSubmit={handleSubmit}>
                  <BootstrapForm.Group controlId="formName">
                    <BootstrapForm.Label className="fw-normal">
                      Enter the name of the team <strong>{activeTeam?.name}</strong> to confirm
                    </BootstrapForm.Label>
                    <Field name="name">
                      {({ field }: FieldProps<string>) => (
                        <BootstrapForm.Control
                          type="text"
                          placeholder={activeTeam?.name}
                          isInvalid={!!errors.name && !!touched.name}
                          {...field}
                        />
                      )}
                    </Field>
                    <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
                  </BootstrapForm.Group>
                </Form>
              </>
            )}
            {!leaveAllowed && (
              <Alert variant="danger">
                You cannot leave the team <strong>{activeTeam?.name}</strong> because it doesn't have any other owners.
                <br />
                Assign the owner role to another user first.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleSubmit()} disabled={!leaveAllowed || isSubmitting}>
              Leave
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default TeamLeaveModal;
