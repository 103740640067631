import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { BackendClient } from "../backend/BackendClient";
import PageSpinner from "./PageSpinner";

function ChangePasswordRedirect() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const backendClient = new BackendClient({
        BASE: import.meta.env.VITE_BACKEND_SERVER_URL,
        TOKEN: async () => await getAccessTokenSilently(),
      });
      const changePassword = async () => {
        const ticketUrl = await backendClient.users.createPasswordChangeTicket();
        window.location.replace(ticketUrl);
      };
      changePassword();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return <PageSpinner />;
}

export default ChangePasswordRedirect;
