import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";

import { TeamPlanLimits } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import Tooltip from "./Tooltip";

type PlanLimitsBarsProps = {
  className?: string;
};

type PlanLimitMetadata = {
  displayName: string;
  tooltipSuffix: string;
};

const LIMITS: {
  [K in keyof TeamPlanLimits]: PlanLimitMetadata;
} = {
  apps: { displayName: "Apps", tooltipSuffix: "apps" },
  instances: { displayName: "App instances", tooltipSuffix: "active app instances" },
  consumers: {
    displayName: "API consumers",
    tooltipSuffix: "unique API consumers",
  },
  users: { displayName: "Dashboard users", tooltipSuffix: "dashboard users" },
};

function PlanLimitsBars({ className }: PlanLimitsBarsProps) {
  const { teamPlan } = useGlobal();
  const limits = teamPlan?.limits;

  if (!limits) return null;

  return (
    <Container fluid className={className}>
      {Object.entries(LIMITS).map(([name, { displayName, tooltipSuffix }]) => {
        const { allowed, actual } = (limits as any)[name];
        const now = allowed ? (actual / allowed) * 100 : 0;
        const label: React.ReactNode = allowed
          ? actual / allowed > 0.25
            ? `${actual} / ${allowed}`
            : undefined
          : "unlimited";
        const variant = now > 100 ? "danger" : now == 100 ? "secondary" : "primary";
        const tooltipPrefix = now > 100 ? <b>LIMIT EXCEEDED: </b> : undefined;
        const tooltip = (
          <>
            {tooltipPrefix}
            {allowed ? `${actual} of ${allowed} allowed` : "Unlimited"} {tooltipSuffix}
          </>
        );
        return (
          <Row key={name}>
            <div style={{ width: 125, display: "inline-block" }} className="small text-muted text-nowrap">
              {displayName}
            </div>
            <Col className="align-content-center">
              <Tooltip tooltip={tooltip} placement="bottom">
                <ProgressBar now={now} label={label} variant={variant} />
              </Tooltip>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}

export default PlanLimitsBars;
