import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

type DetailsModalContentProps = {
  labelValueMap: Record<string, React.ReactNode>;
  modalSize?: "md" | "lg";
};

function DetailsModalContent({ labelValueMap, modalSize = "md" }: DetailsModalContentProps) {
  return (
    <Container className="d-grid gap-2">
      {Object.entries(labelValueMap).map(([label, value]) =>
        value !== undefined && value !== null && value !== "" ? (
          <Row key={label}>
            <Col
              sm={4}
              lg={modalSize == "lg" ? 3 : undefined}
              className="small fw-bold text-sm-end"
              style={{ lineHeight: "1.5rem" }}
            >
              {label}
            </Col>
            <Col>{value}</Col>
          </Row>
        ) : null,
      )}
    </Container>
  );
}

export default DetailsModalContent;
