import { SparklinesLine } from "@lueton/react-sparklines";
import { Placement } from "react-bootstrap/esm/types";
import { Link } from "react-router-dom";

import { getColor } from "../utils/colors";
import Tooltip from "./Tooltip";

type SparklineGraphProps = {
  data?: number[];
  max?: number;
  linkTo: string;
  tooltip?: string;
  tooltipPlacement?: Placement;
  width?: number;
  marginRight?: number;
};

function SparklineGraph({
  data,
  max,
  linkTo,
  tooltip,
  tooltipPlacement = "right",
  width = 300,
  marginRight = 0,
}: SparklineGraphProps) {
  return (
    <Tooltip placement={tooltipPlacement} tooltip={tooltip}>
      <div style={{ width, marginRight }}>
        {data && (
          <Link to={linkTo}>
            <SparklinesLine
              data={data}
              min={0}
              max={max}
              width={width}
              height={45}
              fill={getColor("primary")}
              fillOpacity={0.1}
              stroke={getColor("primary")}
              strokeWidth={0.75}
              margin={{ top: 4, right: 1, bottom: 4, left: 1 }}
            />
          </Link>
        )}
      </div>
    </Tooltip>
  );
}

export default SparklineGraph;
