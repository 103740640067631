import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import PlanLimitsBars from "../components/PlanLimitsBars";
import { useGlobal } from "../contexts/GlobalContext";

type PlanLimitsModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function PlanLimitsModal({ show, setShow }: PlanLimitsModalProps) {
  const { teamPlan } = useGlobal();
  const navigate = useNavigate();
  const lastLimitCheckAt = teamPlan?.limits_last_checked_at
    ? DateTime.fromISO(teamPlan.limits_last_checked_at)
    : undefined;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Your usage vs. plan limits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PlanLimitsBars className="p-0" />
        <div className="small text-muted mt-4">
          We check your usage against the limits of your plan once per hour. The last check was{" "}
          {lastLimitCheckAt?.toRelative()}.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => navigate("/settings?upgradePlan")}>
          Upgrade plan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PlanLimitsModal;
