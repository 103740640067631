import Spinner from "react-bootstrap/Spinner";

import Logo from "../static/logo.svg?react";
import "./PageSpinner.css";

function PageSpinner(): JSX.Element {
  return (
    <div className="PageSpinner">
      <div className="logo-container">
        <Logo className="logo" />
      </div>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default PageSpinner;
