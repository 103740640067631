import "./Method.css";

type MethodProps = {
  method?: string;
};

function Method({ method }: MethodProps): JSX.Element {
  if (!method) {
    return <></>;
  }
  return <span className={`Method ${method.toLowerCase()}`}>{method.toUpperCase()}</span>;
}

export default Method;
