import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapPagination from "react-bootstrap/Pagination";

import "./Pagination.css";

type PaginationProps = {
  page?: number;
  numberOfPages: number;
  setPage: (page: number) => void;
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
};

function Pagination({ page = 1, numberOfPages, setPage, breakpoint = "md" }: PaginationProps) {
  const n = numberOfPages;
  const index = page - 1;
  const indices = Array.from(Array(numberOfPages).keys());
  const className = `d-none d-${breakpoint}-inline`;

  return (
    <BootstrapPagination size="sm" className="Pagination my-0">
      <BootstrapPagination.Prev disabled={index === 0} onClick={() => setPage(page - 1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </BootstrapPagination.Prev>
      {indices.map((i) => {
        const hidden =
          n > 9 &&
          Math.abs(i - index) > 2 &&
          (index > 4 || i > 6) &&
          (index < n - 5 || i < n - 7) &&
          i !== 0 &&
          i !== n - 1;
        const ellipsisBefore = n > 9 && i === n - 1 && index < n - 5;
        const ellipsisAfter = n > 9 && i === 0 && index > 4;
        return (
          <React.Fragment key={i}>
            {ellipsisBefore && <BootstrapPagination.Ellipsis className={className} disabled />}
            {!hidden && (
              <BootstrapPagination.Item className={className} active={i === index} onClick={() => setPage(i + 1)}>
                {i + 1}
              </BootstrapPagination.Item>
            )}
            {ellipsisAfter && <BootstrapPagination.Ellipsis className={className} disabled />}
          </React.Fragment>
        );
      })}
      <BootstrapPagination.Next disabled={index === numberOfPages - 1} onClick={() => setPage(page + 1)}>
        <FontAwesomeIcon icon={faAngleRight} />
      </BootstrapPagination.Next>
    </BootstrapPagination>
  );
}

export default Pagination;
