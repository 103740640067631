import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { useQuery } from "@tanstack/react-query";
import {
  ApiError,
  GetAlertCountsResponse,
  GetAlertCountsResponseItem,
  GetTeamPlanResponse,
  ListAppsResponse,
  ListAppsResponseItem,
  ListTeamsResponse,
  ListTeamsResponseItem,
} from "../backend";
import { BackendClient } from "../backend/BackendClient";
import { ActiveApp } from "../types/ActiveApp";

type GlobalContextType = {
  backendClient?: BackendClient;
  userId?: number;
  userError?: string;
  teams?: ListTeamsResponseItem[];
  apps?: ListAppsResponseItem[];
  alertCounts?: Record<number, GetAlertCountsResponseItem>;
  activeTeam?: ListTeamsResponseItem;
  setActiveTeam: React.Dispatch<React.SetStateAction<ListTeamsResponseItem | undefined>>;
  setActiveTeamIdAfterRefresh: React.Dispatch<React.SetStateAction<number | undefined>>;
  activeApp?: ActiveApp;
  setActiveApp: React.Dispatch<React.SetStateAction<ActiveApp | undefined>>;
  refreshTeams: () => Promise<ListTeamsResponse | undefined> | undefined;
  refreshTeamPlan: () => Promise<GetTeamPlanResponse | undefined> | undefined;
  refreshApps: () => Promise<ListAppsResponse | undefined> | undefined;
  refreshAlertCounts: () => Promise<GetAlertCountsResponse | undefined> | undefined;
  launchDemo: () => void;
  teamPlan?: GetTeamPlanResponse;
  onboardingComplete?: boolean;
  setOnboardingComplete: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isSiteAdmin: boolean;
  isTeamAdmin: boolean;
  isTeamOwner: boolean;
  isDemo: boolean;
  timezone: string;
};

const GlobalContext = React.createContext<GlobalContextType | undefined>(undefined);

export function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalContextProvider");
  }
  return context;
}

export function GlobalContextProvider({ children }: { children: React.ReactNode }) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const posthog = usePostHog();
  const [userId, setUserId] = useState<number>();
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>();
  const [isSiteAdmin, setIsSiteAdmin] = useState(false);
  const [userError, setUserError] = useState<string>();
  const [backendClient, setBackendClient] = useState<BackendClient>();
  const [activeTeam, setActiveTeam] = useState<ListTeamsResponseItem>();
  const [activeTeamIdAfterRefresh, setActiveTeamIdAfterRefresh] = useState<number | undefined>();
  const [activeApp, setActiveApp] = useState<ActiveApp>();
  const [shouldLaunchDemo, setShouldLaunchDemo] = useState<boolean>(false);

  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
  const isTeamAdmin = useMemo(() => {
    return activeTeam?.role === "admin" || activeTeam?.role === "owner";
  }, [activeTeam]);
  const isTeamOwner = useMemo(() => {
    return activeTeam?.role === "owner";
  }, [activeTeam]);
  const isDemo = useMemo(() => {
    return !!activeTeam?.demo;
  }, [activeTeam]);

  useEffect(() => {
    if (isAuthenticated) {
      const backendClient = new BackendClient({
        BASE: import.meta.env.VITE_BACKEND_SERVER_URL,
        TOKEN: async () => await getAccessTokenSilently(),
      });
      backendClient.users
        .createOrUpdateUser()
        .then((data) => {
          setUserId(data.user_id);
          setOnboardingComplete(data.onboarding_complete);
          setIsSiteAdmin(data.site_admin);
          setBackendClient(backendClient);
        })
        .catch((error) => {
          if (error instanceof ApiError) {
            setUserError((error.body as any).detail);
          }
        });
      Sentry.setUser({ id: user?.sub, username: user?.name, email: user?.email });
      posthog.identify(user?.sub, { email: user?.email, name: user?.name });
    } else if (!isLoading) {
      setBackendClient(undefined);
      setActiveTeam(undefined);
      setActiveApp(undefined);
      Sentry.setUser(null);
    }
  }, [isAuthenticated, isLoading, user, getAccessTokenSilently]);

  const teamsQuery = useQuery({
    queryKey: ["teams"],
    queryFn: () => backendClient!.teams.listTeams(),
    enabled: !!backendClient,
  });
  const refreshTeams = useCallback(async () => {
    const result = await teamsQuery.refetch();
    return result.data;
  }, [teamsQuery]);

  const teamPlanQueryParams = { teamId: activeTeam?.id || 0 };
  const teamPlanQuery = useQuery({
    queryKey: ["teamPlan", teamPlanQueryParams],
    queryFn: () => backendClient!.teams.getTeamPlan(teamPlanQueryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const refreshTeamPlan = useCallback(async () => {
    const result = await teamPlanQuery.refetch();
    return result.data;
  }, [teamPlanQuery]);

  const appsQueryParams = { teamId: activeTeam?.id || 0 };
  const appsQuery = useQuery({
    queryKey: ["apps", appsQueryParams],
    queryFn: () => backendClient!.apps.listApps(appsQueryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const refreshApps = useCallback(async () => {
    const result = await appsQuery.refetch();
    return result.data;
  }, [appsQuery]);

  const alertCountsQueryParams = { teamId: activeTeam?.id || 0 };
  const alertCountsQuery = useQuery({
    queryKey: ["alertCounts", alertCountsQueryParams],
    queryFn: () => backendClient!.alerts.getAlertCounts(alertCountsQueryParams),
    enabled: !!backendClient && !!activeTeam,
    refetchInterval: 60000,
  });
  const refreshAlertCounts = useCallback(async () => {
    const result = await alertCountsQuery.refetch();
    return result.data;
  }, [alertCountsQuery]);

  const launchDemo = useCallback(() => {
    if (backendClient && teamsQuery.data) {
      setShouldLaunchDemo(true);
      if (!teamsQuery.data.some((team) => team.demo)) {
        const joinDemoTeam = async () => {
          await backendClient.users.joinDemoTeam();
          await refreshTeams();
        };
        const promise = joinDemoTeam();
        toast.promise(promise, {
          pending: "Joining demo team...",
          success: "Joined demo team!",
          error: "Failed to join demo team.",
        });
      }
    }
  }, [backendClient, teamsQuery.data]);

  useEffect(() => {
    if (shouldLaunchDemo && teamsQuery.data) {
      const demoTeam = teamsQuery.data.find((team) => team.demo);
      if (demoTeam) {
        setActiveTeam(demoTeam);
        setShouldLaunchDemo(false);
      }
    }
  }, [shouldLaunchDemo, teamsQuery.data]);

  useEffect(() => {
    if (activeTeamIdAfterRefresh && teamsQuery.data) {
      const newActiveTeam = teamsQuery.data.find((team) => team.id === activeTeamIdAfterRefresh);
      if (newActiveTeam) {
        setActiveTeam(newActiveTeam);
        setActiveTeamIdAfterRefresh(undefined);
      }
    }
  }, [activeTeamIdAfterRefresh, teamsQuery.data]);

  useEffect(() => {
    if (teamsQuery.data && teamsQuery.data.length > 0) {
      setActiveTeam((activeTeam) => {
        const team = teamsQuery.data.find((team) => team.id === activeTeam?.id);
        if (!activeTeam || !team) {
          let newActiveTeam = teamsQuery.data[0];
          const storedActiveTeamId = Number(localStorage.getItem("activeTeamId"));
          if (storedActiveTeamId) {
            const storedActiveTeam = teamsQuery.data.find((team) => team.id === storedActiveTeamId);
            if (storedActiveTeam) {
              newActiveTeam = storedActiveTeam;
            }
          }
          return newActiveTeam;
        } else {
          activeTeam.name = team.name;
          activeTeam.slug = team.slug;
          activeTeam.demo = team.demo;
          activeTeam.role = team.role;
          return activeTeam;
        }
      });
    }
  }, [teamsQuery.data]);

  useEffect(() => {
    if (appsQuery.data && appsQuery.data.length > 0) {
      setActiveApp((activeApp) => {
        const app = appsQuery.data.find((app) => app.id === activeApp?.id);
        if (!activeApp || !app) {
          return undefined;
        } else {
          activeApp.name = app.name;
          activeApp.slug = app.slug;
          activeApp.suspended = app.suspended;
          return activeApp;
        }
      });
    }
  }, [appsQuery.data]);

  useEffect(() => {
    if (activeTeam) {
      localStorage.setItem("activeTeamId", activeTeam.id.toString());
    }
  }, [activeTeam]);

  return (
    <GlobalContext.Provider
      value={{
        backendClient,
        userId,
        userError,
        teams: teamsQuery.data,
        apps: appsQuery.data,
        alertCounts: alertCountsQuery.data,
        activeTeam,
        setActiveTeam,
        setActiveTeamIdAfterRefresh,
        activeApp,
        setActiveApp,
        refreshTeams,
        refreshTeamPlan,
        refreshApps,
        refreshAlertCounts,
        launchDemo,
        teamPlan: teamPlanQuery.data,
        onboardingComplete,
        setOnboardingComplete,
        isSiteAdmin,
        isTeamAdmin,
        isTeamOwner,
        isDemo,
        timezone,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
