import { Field, FieldProps, Form, Formik } from "formik";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { default as BootstrapForm } from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const POSTHOG_SURVEY_ID = "018e0dfe-033e-0000-1ef3-5f02623fa4dc";

type FeedbackModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function FeedbackModal({ show, setShow }: FeedbackModalProps) {
  const posthog = usePostHog();

  useEffect(() => {
    if (show) {
      posthog.capture("survey shown", {
        $survey_id: POSTHOG_SURVEY_ID,
      });
    }
  }, [show]);

  const closeModal = () => {
    setShow(false);
    posthog.capture("survey dismissed", {
      $survey_id: POSTHOG_SURVEY_ID,
    });
  };

  return (
    <Formik
      initialValues={{ response: "" }}
      onSubmit={async (values, { resetForm }) => {
        posthog.capture("survey sent", {
          $survey_id: POSTHOG_SURVEY_ID,
          $survey_response: values.response.trim(),
        });
        toast.success("Thank you for your feedback!");
        resetForm();
        setShow(false);
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Modal show={show} onHide={() => closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formResponse">
                <BootstrapForm.Label>What can we do to improve Apitally?</BootstrapForm.Label>
                <Field name="response">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      as="textarea"
                      placeholder="Start typing..."
                      rows={5}
                      maxLength={4096}
                      autoFocus
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.response}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => closeModal()}>
                Discard
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default FeedbackModal;
