import classNames from "classnames";

import "./Path.css";

type PathProps = {
  path?: string;
};

function Path({ path }: PathProps): JSX.Element {
  if (!path) {
    return <></>;
  }
  const splitPath = path.split("/");
  const pathElements: JSX.Element[] = [];
  splitPath.forEach((part, index) => {
    const isParam = part.startsWith("{") || part.startsWith("<");
    if (index > 0) {
      pathElements.push(
        <span key={`${index}-sep`} className="sep">
          /
        </span>,
      );
    }
    pathElements.push(
      <span key={index} className={classNames({ param: isParam })}>
        {part}
      </span>,
    );
  });

  return (
    <span className="Path" title={path}>
      {pathElements}
    </span>
  );
}

export default Path;
