import MethodBadge from "./MethodBadge";
import "./MethodPath.css";
import Path from "./Path";

type MethodPathProps = {
  method: string;
  path: string;
};

function MethodPath({ method, path }: MethodPathProps): JSX.Element {
  return (
    <div className="MethodPath">
      <MethodBadge method={method} />
      <Path path={path} />
    </div>
  );
}

export default MethodPath;
