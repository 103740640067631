import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import capitalize from "lodash/capitalize";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import BootstrapForm from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Stack from "react-bootstrap/Stack";
import { toast } from "react-toastify";

import { useGlobal } from "../contexts/GlobalContext";
import { validateEmail } from "../utils/email";
import { ApiError, InviteUserRequestBody } from "./../backend";
import "./InviteForm.css";
import Tooltip from "./Tooltip";

interface InviteFormValues {
  email: string;
  role: string;
}

function InviteForm() {
  const { backendClient, activeTeam, teamPlan } = useGlobal();
  const queryClient = useQueryClient();
  const userLimitReached =
    !!teamPlan?.limits.users.allowed && teamPlan.limits.users.actual >= teamPlan.limits.users.allowed;
  const initialValues: InviteFormValues = { email: "", role: "member" };

  const validateInviteForm = (values: InviteFormValues) => {
    const errors: Partial<InviteFormValues> = {};
    if (!validateEmail(values.email)) {
      errors.email = "Valid email address required.";
    }
    return errors;
  };

  const sendInvite = async (email: string, role: "member" | "admin" | "owner") => {
    if (backendClient && activeTeam) {
      const promise = backendClient.teams.inviteUser({
        teamId: activeTeam.id,
        requestBody: {
          email: email,
          role: role as InviteUserRequestBody["role"],
        },
      });
      toast.promise(promise, {
        pending: "Sending invite...",
        success: "Invite sent!",
        error: {
          render({ data }) {
            if (data instanceof ApiError && data.status === 409) {
              return "A user or invite with this email address already exists.";
            } else {
              return "Failed to send invite.";
            }
          },
        },
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateInviteForm}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting, resetForm, validateForm }) => {
        try {
          await sendInvite(values.email, values.role as "member" | "admin" | "owner");
          resetForm();
          validateForm(initialValues);
          queryClient.invalidateQueries({ queryKey: ["team", { teamId: activeTeam?.id || 0 }] });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, isValid, values, setFieldValue }) => (
        <Form className="InviteForm" onSubmit={handleSubmit}>
          <Stack direction="horizontal" gap={2}>
            <InputGroup hasValidation>
              <InputGroup.Text className="text-secondary">
                <FontAwesomeIcon icon={faUserPlus} />
              </InputGroup.Text>
              <Field name="email">
                {({ field }: FieldProps<string>) => (
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Email address to invite to the team"
                    maxLength={256}
                    isInvalid={!!errors.email && !!touched.email && values.email.trim() !== ""}
                    required
                    {...field}
                  />
                )}
              </Field>
              <DropdownButton variant="light" title={capitalize(values.role)} align="end">
                {["member", "admin", "owner"].map((role) =>
                  activeTeam?.role === "owner" || role !== "owner" ? (
                    <Dropdown.Item key={role} eventKey={role} onClick={() => setFieldValue("role", role)}>
                      {capitalize(role)}
                    </Dropdown.Item>
                  ) : undefined,
                )}
              </DropdownButton>
            </InputGroup>
            <Tooltip
              condition={userLimitReached}
              tooltip="Your team has reached its user limit. Upgrade your plan to invite more users."
              placement="bottom"
            >
              <Button
                variant="primary"
                className="text-nowrap send"
                disabled={userLimitReached || !isValid || isSubmitting}
                onClick={() => handleSubmit()}
              >
                Send invite
              </Button>
            </Tooltip>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default InviteForm;
