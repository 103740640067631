import semver from "semver";

import { AppEnvItem, ListAppsResponseItem } from "../backend";

export function checkClientVersion({
  app,
  env,
  python,
  js,
}: {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  python?: string;
  js?: string;
}) {
  const envs = (env ? [env] : app.envs).filter(
    (env) =>
      env.client &&
      env.versions?.apitally &&
      ((python && env.client.startsWith("python:")) || (js && env.client.startsWith("js:"))),
  );
  if (envs.length === 0) {
    return true;
  }
  return envs.every((env) => {
    if (env.client && env.versions?.apitally) {
      try {
        if (python && env.client.startsWith("python:") && !semver.lt(env.versions.apitally, python)) {
          return true;
        } else if (js && env.client.startsWith("js:") && !semver.lt(env.versions.apitally, js)) {
          return true;
        }
      } catch (e) {
        return true;
      }
    }
    return false;
  });
}
