import { DateTime } from "luxon";
import { useState } from "react";
import Button from "react-bootstrap/Button";

import { useGlobal } from "../contexts/GlobalContext";
import PlanLimitsModal from "../modals/PlanLimitsModal";
import UpgradePlanAlert from "./UpgradePlanAlert";

function PlanLimitsExceededAlert() {
  const { apps, teamPlan } = useGlobal();
  const [showModal, setShowModal] = useState(false);

  let alertVariant = "danger";
  let alertText: React.ReactNode | undefined;
  if (teamPlan?.limits_status === "exceeded") {
    alertText = (
      <>
        Your team is exceeding the limits of the {teamPlan.name}. As your grace period has ended, we've temporarily
        paused data ingestion for your apps until you upgrade your plan or usage is back within the allowed limits.
      </>
    );
  } else if (teamPlan && apps?.some((app) => app.suspended && app.suspended_reasons.includes("app_limit_exceeded"))) {
    alertText = (
      <>Your team has more apps than allowed in the {teamPlan.name}. Please delete apps or upgrade your plan.</>
    );
  } else if (teamPlan?.limits_status === "grace" && teamPlan.limits_grace_period_ends_at) {
    const gracePeriodEnd = DateTime.fromISO(teamPlan.limits_grace_period_ends_at);
    alertVariant = "primary";
    alertText = (
      <>
        Your team is exceeding the limits of the {teamPlan.name} and is now in a one-time grace period, during which you
        can continue to trial Apitally and upgrade your plan without any disruptions. This grace period will end on{" "}
        <b>{gracePeriodEnd.toLocaleString(DateTime.DATE_MED)}</b>.
      </>
    );
  }

  if (!alertText) {
    return <></>;
  }

  const moreInfoButton = (
    <Button variant={`outline-${alertVariant}`} onClick={() => setShowModal(true)}>
      Show details
    </Button>
  );
  return (
    <>
      <PlanLimitsModal show={showModal} setShow={setShowModal} />
      <UpgradePlanAlert variant={alertVariant} extraButtons={moreInfoButton}>
        {alertText}
      </UpgradePlanAlert>
    </>
  );
}

export default PlanLimitsExceededAlert;
