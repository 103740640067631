import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";

type RefreshButtonProps = {
  onClick: () => void | Promise<void>;
  loading?: boolean;
};

function RefreshButton({ onClick, loading }: RefreshButtonProps) {
  return (
    <Button variant="primary" disabled={loading} onClick={onClick}>
      <FontAwesomeIcon icon={faRotateRight} className={loading ? "fa-spin" : undefined} />
    </Button>
  );
}

export default RefreshButton;
