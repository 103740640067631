export function formatResponseTime(ms: number, msMax?: number | undefined) {
  if (msMax === undefined) {
    msMax = ms;
  }
  const sMax = msMax / 1000;
  if (sMax > 10) {
    return `${(ms / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 })} s`;
  } else {
    return `${ms.toLocaleString(undefined, { maximumFractionDigits: 0 })} ms`;
  }
}

export function formatDataSize(kb: number, kbMax?: number | undefined) {
  if (kbMax === undefined) {
    kbMax = kb;
  }
  const mbMax = kbMax / 1000;
  const gbMax = mbMax / 1000;
  if (gbMax > 1) {
    return `${(kb / 1000000).toLocaleString(undefined, { maximumFractionDigits: 1 })} GB`;
  } else if (mbMax > 1) {
    return `${(kb / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 })} MB`;
  } else {
    return `${kb.toLocaleString(undefined, { maximumFractionDigits: 1 })} KB`;
  }
}
