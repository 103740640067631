import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, Chart as ChartJS, LinearScale, TimeSeriesScale, Title, Tooltip } from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type ConsumersBarChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  period: string;
  setPeriod?: (period: string) => void;
};

function ConsumersBarChart({ app, env, period, setPeriod }: ConsumersBarChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["consumersChart", queryParams],
    queryFn: () => backendClient!.consumers.getConsumersChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const chartOptions = merge(
      getChartOptions({
        labels: query.data.time_windows,
        title: "Number of unique consumers",
        setPeriod,
      }),
      {
        scales: { y: { ticks: { precision: 0 } } },
      },
    );
    const chartData = {
      labels: query.data.time_windows,
      datasets: [
        {
          label: "Consumers",
          data: query.data.consumer_counts,
          backgroundColor: primaryColor,
          hoverBackgroundColor: primaryColor,
        },
      ],
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ConsumersBarChart);
