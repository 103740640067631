import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";

import "./ActionsDropdown.css";

function ActionsDropdown({ children, ...divProps }: { children: React.ReactNode } & React.HTMLProps<HTMLDivElement>) {
  const icon = (
    <span className="text-secondary">
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </span>
  );
  return (
    <div className="ActionsDropdown" {...divProps}>
      <DropdownButton title={icon} align="end" variant="light" onClick={(e) => e.stopPropagation()}>
        {children}
      </DropdownButton>
    </div>
  );
}

export default ActionsDropdown;
