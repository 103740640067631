import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScriptableContext,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import annotationPlugin from "chartjs-plugin-annotation";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);
ChartJS.register(annotationPlugin);

type ApdexScoreLineChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  endpoint?: Endpoint;
  period: string;
  displayTitle?: boolean;
  style?: React.CSSProperties;
};

function ApdexScoreLineChart({ app, env, endpoint, period, displayTitle = true, style }: ApdexScoreLineChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["apdexScoreChart", queryParams],
    queryFn: () => backendClient!.performance.getApdexScoreChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess) {
    const minY = query.data.apdex_scores.every((x) => x === null || x >= 0.5) ? 0.5 : 0;
    const chartOptions = merge(
      getChartOptions({
        labels: query.data.time_windows,
        title: displayTitle ? "Apdex score" : undefined,
      }),
      {
        scales: {
          y: {
            ticks: {
              stepSize: 0.01,
              autoSkip: false,
              callback: (value: string | number) =>
                typeof value === "number" && [0, 0.5, 0.7, 0.85, 0.93, 1.0].includes(value) ? value : undefined,
            },
            suggestedMin: minY,
            suggestedMax: 1,
            beginAtZero: false,
          },
        },
        interaction: { mode: "index" as const, intersect: false },
        plugins: {
          annotation: {
            annotations: {
              excellent: {
                drawTime: "beforeDraw" as const,
                type: "box" as const,
                yMin: 0.93,
                yMax: 1.0,
                backgroundColor: "#aadbdb88",
                borderWidth: 0,
              },
              good: {
                drawTime: "beforeDraw" as const,
                type: "box" as const,
                yMin: 0.85,
                yMax: 0.93,
                backgroundColor: "#aadbdb44",
                borderWidth: 0,
              },
              fair: {
                drawTime: "beforeDraw" as const,
                type: "box" as const,
                yMin: 0.7,
                yMax: 0.85,
                backgroundColor: "#6c757d16",
                borderWidth: 0,
              },
              poor: {
                drawTime: "beforeDraw" as const,
                type: "box" as const,
                yMin: 0.5,
                yMax: 0.7,
                backgroundColor: "#dea6a644",
                borderWidth: 0,
              },
              unacceptable: {
                drawTime: "beforeDraw" as const,
                type: "box" as const,
                yMin: 0,
                yMax: 0.5,
                backgroundColor: "#dea6a688",
                borderWidth: 0,
              },
            },
          },
        },
      },
    );
    const getGradient = (context: ScriptableContext<"line">) => {
      const { ctx, chartArea } = context.chart;
      if (!chartArea) {
        return; // happens on initial chart load
      }
      const dangerColor = getColor("danger");
      const secondaryColor = getColor("secondary");
      const primaryColor = getColor("primary");
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, dangerColor);
      if (minY === 0) {
        gradient.addColorStop(0.7, dangerColor);
        gradient.addColorStop(0.7, secondaryColor);
        gradient.addColorStop(0.85, secondaryColor);
        gradient.addColorStop(0.85, primaryColor);
      } else if (minY === 0.5) {
        gradient.addColorStop(0.4, dangerColor);
        gradient.addColorStop(0.4, secondaryColor);
        gradient.addColorStop(0.7, secondaryColor);
        gradient.addColorStop(0.7, primaryColor);
      }
      gradient.addColorStop(1.0, primaryColor);
      return gradient;
    };
    // const data = query.data.apdex_scores;
    // data[3] = 0.7;
    // data[4] = 0.5;
    // data[5] = 0.6;
    // data[6] = 0.8;
    const chartData: ChartData<"line"> = {
      labels: query.data.time_windows,
      datasets: [
        {
          label: "Apdex score",
          data: query.data.apdex_scores,
          borderColor: getGradient,
          backgroundColor: getGradient,
          cubicInterpolationMode: "monotone",
          pointStyle: false,
        },
      ],
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%", ...style }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ApdexScoreLineChart);
