import DjangoIcon from "../static/django-icon.svg?react";
import ExpressIcon from "../static/express-icon.svg?react";
import FastAPIIcon from "../static/fastapi-icon.svg?react";
import FastifyIcon from "../static/fastify-icon.svg?react";
import FlaskIcon from "../static/flask-icon.svg?react";
import KoaIcon from "../static/koa-icon.svg?react";
import LitestarIcon from "../static/litestar-icon.svg?react";
import NestJSIcon from "../static/nestjs-icon.svg?react";
import StarletteIcon from "../static/starlette-icon.svg?react";

type AppFrameworkIconProps = {
  framework: string;
  active?: boolean;
  height?: number;
};

function AppFrameworkIcon({ framework, active = true, height = 32 }: AppFrameworkIconProps) {
  const svgProps = {
    title: framework,
    width: undefined,
    height: height,
    style: {
      filter: !active ? "saturate(0%) brightness(150%)" : undefined,
      marginRight: -2,
      marginTop: -2,
      marginBottom: -2,
    },
  };

  const fw = framework.toLowerCase();
  let logo;
  if (fw === "django ninja") {
    logo = <DjangoIcon {...svgProps} />;
  } else if (fw === "django rest framework") {
    logo = <DjangoIcon {...svgProps} />;
  } else if (fw === "express") {
    logo = <ExpressIcon {...svgProps} />;
  } else if (fw === "fastapi") {
    logo = <FastAPIIcon {...svgProps} />;
  } else if (fw === "fastify") {
    logo = <FastifyIcon {...svgProps} />;
  } else if (fw === "flask") {
    logo = <FlaskIcon {...svgProps} />;
  } else if (fw === "koa") {
    logo = <KoaIcon {...svgProps} />;
  } else if (fw === "litestar") {
    logo = <LitestarIcon {...svgProps} />;
  } else if (fw === "nestjs") {
    logo = <NestJSIcon {...svgProps} />;
  } else if (fw === "starlette") {
    logo = <StarletteIcon {...svgProps} />;
  }

  return <span title={framework}>{logo}</span> || <></>;
}

export default AppFrameworkIcon;
