import { useEffect } from "react";

function LemonSqueezy() {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.innerHTML = `window.lemonSqueezyAffiliateConfig = { store: "apitally" };`;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://lmsqueezy.com/affiliate.js";
    script2.defer = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "https://app.lemonsqueezy.com/js/lemon.js";
    script3.defer = true;
    document.body.appendChild(script3);
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, []);

  return <></>;
}

export default LemonSqueezy;
