import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";

import CustomIcon from "./CustomIcon";
import "./IconButton.css";

type IconButtonProps = {
  icon: IconDefinition | string;
  iconClassName?: string;
  className?: string;
};

function IconButton({
  icon,
  iconClassName = "text-secondary",
  className,
  ...buttonProps
}: IconButtonProps & React.ComponentProps<typeof Button>) {
  const mergedClassName = `IconButton ${className || ""}`.trimEnd();
  const iconElement =
    typeof icon === "string" ? (
      <CustomIcon src={icon} className={iconClassName} />
    ) : (
      <FontAwesomeIcon icon={icon as IconDefinition} className={iconClassName} />
    );
  return (
    <Button className={mergedClassName} variant="light" {...buttonProps}>
      <span>{iconElement}</span>
    </Button>
  );
}

export default IconButton;
