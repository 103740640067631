import { useAuth0 } from "@auth0/auth0-react";
import { faCommentDots, faLifeRing, faRightFromBracket, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import { useGlobal } from "../contexts/GlobalContext";

type UserDropdownMenuProps = {
  includeSupportAndFeedback?: boolean;
  setShowFeedbackModal?: (show: boolean) => void;
} & React.ComponentPropsWithoutRef<typeof Dropdown.Menu>;

function UserDropdownMenu({ includeSupportAndFeedback, setShowFeedbackModal, ...props }: UserDropdownMenuProps) {
  const { logout } = useAuth0();
  const { teams, apps, activeTeam, setActiveTeam, launchDemo } = useGlobal();
  const appsLoaded = apps !== undefined;
  const hasAppsWithData = apps && apps.some((app) => app.envs.some((env) => !!env.last_sync_at));
  const isMemberOfDemoTeam = teams?.some((team) => team.demo);
  const canLaunchDemo = !isMemberOfDemoTeam && appsLoaded && !hasAppsWithData && !(teams && teams.length > 1);

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Dropdown.Menu {...props}>
      {canLaunchDemo && (
        <>
          <Dropdown.Item onClick={() => launchDemo()}>
            <FontAwesomeIcon icon={faRocket} fixedWidth className="text-secondary" />
            Launch demo
          </Dropdown.Item>
          <Dropdown.Divider />
        </>
      )}
      {teams && teams.length > 1 && (
        <>
          <Dropdown.Header>Switch team</Dropdown.Header>
          {teams.map((team) => (
            <Dropdown.Item
              key={team.id}
              onClick={() => setActiveTeam(team)}
              active={team === activeTeam}
              className="team"
            >
              {team.name}
              {team.demo && (
                <Badge
                  pill
                  bg={team === activeTeam ? "light" : "primary"}
                  className={team === activeTeam ? "outline-light ms-2" : "outline-primary ms-2"}
                >
                  Demo
                </Badge>
              )}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
        </>
      )}
      {includeSupportAndFeedback && setShowFeedbackModal && (
        <>
          <Dropdown.Item href="https://docs.apitally.io/support" target="_blank">
            <FontAwesomeIcon icon={faLifeRing} fixedWidth className="text-secondary" />
            Support
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowFeedbackModal(true)}>
            <FontAwesomeIcon icon={faCommentDots} fixedWidth className="text-secondary" />
            Feedback
          </Dropdown.Item>
          <Dropdown.Divider />
        </>
      )}
      <Dropdown.Item className="text-danger" onClick={handleLogout}>
        <FontAwesomeIcon icon={faRightFromBracket} fixedWidth />
        Log out
      </Dropdown.Item>
    </Dropdown.Menu>
  );
}

export default UserDropdownMenu;
