import { faAngleRight, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flatMap from "lodash/flatMap";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import Stack from "react-bootstrap/Stack";

import { useGlobal } from "../contexts/GlobalContext";
import "./PageHeader.css";

function addIndexAsKeys(children: React.ReactNode[]): React.ReactNode[] {
  return children.map((child, index) => {
    return <React.Fragment key={index}>{child}</React.Fragment>;
  });
}

export function intersperseWithIcon(children: React.ReactNode[], icon: React.ReactNode): React.ReactNode[] {
  return addIndexAsKeys(
    flatMap(children, (child, index, array) => (index === array.length - 1 ? child : [child, icon])),
  );
}

export function firstBold(children: React.ReactNode[]): React.ReactNode[] {
  return children.map((child, index) => {
    return index === 0 ? <b>{child}</b> : child;
  });
}

type PageHeaderProps = {
  children: React.ReactNode;
  breakpoint?: "sm" | "md" | "lg" | "xl";
  buttons?: React.ReactNode;
  dropdownMenu?: React.ReactNode;
  noDemoBadge?: boolean;
};

function PageHeader({ children, breakpoint = "md", buttons, dropdownMenu, noDemoBadge }: PageHeaderProps) {
  const { activeTeam } = useGlobal();

  const childrenArray = React.Children.toArray(children);
  const responsiveTitle = childrenArray.length > 1;
  const demoBadge =
    !noDemoBadge && activeTeam?.demo ? (
      <small className="d-none d-sm-inline ms-3">
        <Badge pill bg="primary" className="outline-primary" style={{ marginBottom: "-0.35em" }}>
          Demo
        </Badge>
      </small>
    ) : undefined;
  const angleRightIcon = <FontAwesomeIcon icon={faAngleRight} className="mx-2 text-primary fs-5" />;
  return (
    <div className="PageHeader mx-n3 mx-md-n5 mx-xl-n7 mb-5 mb-xl-6 py-4 py-xl-5 border-bottom">
      <Stack direction="horizontal" gap={3} className="mx-3 mx-md-5 mx-xl-7">
        <div className="title mt-auto">
          {responsiveTitle && (
            <>
              <h3 className={`mt-0 mb-2 d-${breakpoint}-none fw-bold`}>{childrenArray[0]}</h3>
              <h2 className={`my-0 d-${breakpoint}-none`}>
                {intersperseWithIcon(childrenArray.slice(1), angleRightIcon)}
                {demoBadge}
              </h2>
              <h2 className={`my-0 d-none d-${breakpoint}-block`}>
                {intersperseWithIcon(firstBold(childrenArray), angleRightIcon)}
                {demoBadge}
              </h2>
            </>
          )}
          {!responsiveTitle && (
            <h2 className="m-0">
              {intersperseWithIcon(firstBold(childrenArray), angleRightIcon)}
              {demoBadge}
            </h2>
          )}
        </div>
        {buttons && (
          <div className="ms-auto mt-auto d-none d-md-block action-buttons">
            <Stack direction="horizontal" gap={2}>
              {buttons}
            </Stack>
          </div>
        )}
        {dropdownMenu && (
          <div className="ms-auto mt-auto d-md-none action-dropdown">
            <Dropdown align="end" className="no-caret">
              <Dropdown.Toggle variant="outline-light">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Dropdown.Toggle>
              {dropdownMenu}
            </Dropdown>
          </div>
        )}
      </Stack>
    </div>
  );
}

export default PageHeader;
