import { useAuth0 } from "@auth0/auth0-react";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import { useGlobal } from "../contexts/GlobalContext";

function UnverifiedEmailAlert() {
  const { user } = useAuth0();
  const { backendClient } = useGlobal();

  const sendVerificationEmail = async () => {
    if (backendClient) {
      const promise = backendClient.users.sendVerificationEmail();
      toast.promise(promise, {
        pending: "Sending verification email...",
        success: "Verification email sent!",
        error: "Failed to send verification email.",
      });
      await promise;
    }
  };

  return (
    <Alert variant="danger">
      <div>
        Please verify your email address <b>{user?.email}</b> by clicking on the verification link sent to you, then{" "}
        <Alert.Link href="javascript:window.location.reload()">refresh this page</Alert.Link>.
      </div>
      <div className="mt-2">
        <i>Note:</i> Creating apps and teams in Apitally requires a verified email address.
      </div>
      <div className="mt-4 small">
        <Button
          variant="link"
          className="small"
          style={{ color: "unset", fontSize: "0.875rem" }}
          onClick={() => sendVerificationEmail()}
        >
          <FontAwesomeIcon icon={faRotateRight} style={{ marginRight: "0.375rem" }} />
          Resend verification link
        </Button>
      </div>
    </Alert>
  );
}

export default UnverifiedEmailAlert;
