import { faAngleRight, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Stack from "react-bootstrap/Stack";

import { firstBold, intersperseWithIcon } from "./PageHeader";
import "./PageSubHeader.css";

type PageSubHeaderProps = {
  children: React.ReactNode;
  description?: React.ReactNode;
  buttons?: React.ReactNode;
  dropdownMenu?: React.ReactNode;
  noDemoBadge?: boolean;
};

function PageSubHeader({ children, description, buttons, dropdownMenu }: PageSubHeaderProps) {
  const childrenArray = React.Children.toArray(children);
  const angleRightIcon = <FontAwesomeIcon icon={faAngleRight} className="seperator-icon text-primary" />;
  return (
    <div className="PageSubHeader mt-6 mb-4">
      <Stack direction="horizontal" gap={3}>
        <div className="title mt-auto">
          <h3 className="m-0">{intersperseWithIcon(firstBold(childrenArray), angleRightIcon)}</h3>
          {description && <div className="text-muted small mt-1">{description}</div>}
        </div>
        {buttons && (
          <div className="ms-auto mt-auto d-none d-md-block action-buttons">
            <Stack direction="horizontal" gap={2}>
              {buttons}
            </Stack>
          </div>
        )}
        {dropdownMenu && (
          <div className="ms-auto mt-auto d-md-none action-dropdown">
            <Dropdown align="end" className="no-caret">
              <Dropdown.Toggle variant="outline-light">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Dropdown.Toggle>
              {dropdownMenu}
            </Dropdown>
          </div>
        )}
      </Stack>
    </div>
  );
}

export default PageSubHeader;
