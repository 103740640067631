import { User } from "@auth0/auth0-react";
import md5 from "md5";
import React, { useState } from "react";
import Image, { ImageProps } from "react-bootstrap/Image";

import darkUserIcon from "../static/user-dark.svg";
import lightUserIcon from "../static/user-light.svg";

function getGravatarURL(email?: string) {
  const address = String(email).trim().toLowerCase();
  const hash = md5(address);
  return `https://www.gravatar.com/avatar/${hash}?d=404`;
}

interface UserImageProps extends Omit<ImageProps, "src" | "alt" | "roundedCircle" | "referrerPolicy" | "onError"> {
  user?: User;
  variant?: "light" | "dark";
}

function UserImage({ user, variant = "light", ...props }: UserImageProps) {
  const [error, setError] = useState(false);
  const src = user?.picture || getGravatarURL(user?.email);

  return error ? (
    <Image src={variant === "light" ? lightUserIcon : darkUserIcon} alt={user?.name} roundedCircle {...props} />
  ) : (
    <Image
      src={src}
      alt={user?.name}
      roundedCircle
      referrerPolicy="no-referrer"
      onError={() => setError(true)}
      {...props}
    />
  );
}

export default UserImage;
