import { forwardRef } from "react";
import Badge from "react-bootstrap/Badge";

type AppEnv = {
  name: string;
  online?: boolean;
};

type AppEnvBadgeProps = {
  env: AppEnv | undefined;
} & Omit<React.ComponentPropsWithoutRef<typeof Badge>, "bg" | "pill">;

function AppEnvBadge({ env, className, ...badgeProps }: AppEnvBadgeProps, ref: React.Ref<HTMLSpanElement>) {
  if (!env) {
    return <></>;
  }
  return (
    <Badge pill={false} bg={env.online ? "primary" : "secondary"} className={className} ref={ref} {...badgeProps}>
      {env.name}
    </Badge>
  );
}

export default forwardRef(AppEnvBadge);
