import { faStethoscope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import BootstrapForm from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";

import { ApiError, AppEnvItem, ListAppsResponseItem } from "../backend";
import AppEnvBadge from "../components/AppEnvBadge";
import Tooltip from "../components/Tooltip";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useGlobal } from "../contexts/GlobalContext";

type HealthCheckListGroupItemProps = {
  app: ListAppsResponseItem;
  env: AppEnvItem;
  registerFormikRef: (app_env: string, formikInstance: React.RefObject<FormikProps<HealthCheckFormValues>>) => void;
  unregisterFormikRef: (app_env: string) => void;
  disabled: boolean;
};

interface HealthCheckFormValues {
  enabled: boolean;
  url: string;
}

function HealthCheckListGroupItem({
  app,
  env,
  registerFormikRef,
  unregisterFormikRef,
  disabled,
}: HealthCheckListGroupItemProps) {
  const { backendClient, isTeamAdmin } = useGlobal();
  const [initialValues, setInitialValues] = useState<HealthCheckFormValues>({
    enabled: env.health_check_enabled,
    url: env.health_check_url || "",
  });
  const [editUrl, setEditUrl] = useState(false);
  const formikRef = useRef<FormikProps<HealthCheckFormValues>>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editUrl && formikRef) {
      registerFormikRef(env.slug, formikRef);
    } else {
      unregisterFormikRef(env.slug);
    }
  }, [editUrl, formikRef]);

  useEffect(() => {
    if (editUrl && urlInputRef.current) {
      urlInputRef.current.focus();
    }
  }, [editUrl]);

  const updateHealthCheckSettings = async (enabled: boolean, url?: string | null) => {
    if (backendClient && (enabled !== initialValues.enabled || (enabled && url !== initialValues.url))) {
      const promise = backendClient.apps.updateAppEnv({
        appId: app.id,
        appEnvId: env.id,
        requestBody: {
          health_check_enabled: enabled,
          health_check_url: enabled ? url : undefined,
        },
      });
      toast.promise(promise, {
        pending: "Updating health check settings...",
        success: "Health check settings updated!",
        error: "Failed to update health check settings.",
      });
      await promise;
    }
  };

  const validateUrl = (url: string) => {
    try {
      const newUrl = new URL(url);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (e) {
      return false;
    }
  };

  return (
    <ListGroup.Item key={env.id}>
      <Formik<HealthCheckFormValues>
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<typeof values> = {};
          if (values.enabled && (!values.url.trim() || !validateUrl(values.url))) {
            errors.url = "Valid URL required";
          }
          return errors;
        }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          if (!isTeamAdmin || disabled) {
            return;
          }
          try {
            await updateHealthCheckSettings(values.enabled, values.url);
            setInitialValues(values);
            setEditUrl(false);
          } catch (e) {
            if (e instanceof ApiError && e.status === 422) {
              setFieldError("url", "Invalid URL");
            }
          } finally {
            setSubmitting(false);
          }
        }}
        innerRef={formikRef}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} style={{ display: "contents" }}>
            <Container fluid>
              <Row className="py-1">
                <Col xs={6} className="d-flex align-items-center ps-0">
                  <AppEnvBadge env={env} />
                </Col>
                <Col xs={6} className="d-flex align-items-center pe-0">
                  <Field name="enabled">
                    {({ field: { value, onChange, ...fieldProps } }: FieldProps<boolean>) => (
                      <BootstrapForm.Check
                        type="switch"
                        reverse
                        label={value ? "Enabled" : "Disabled"}
                        className="ms-auto"
                        checked={value}
                        disabled={!isTeamAdmin || disabled}
                        onChange={async (e) => {
                          onChange(e);
                          if (e.target.checked) {
                            setEditUrl(true);
                          } else {
                            setFieldValue("url", initialValues.url);
                            setEditUrl(false);
                            handleSubmit();
                          }
                        }}
                        {...fieldProps}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row className="py-1 mt-1">
                <Col xs={12} className="d-flex align-items-center px-0">
                  <Field name="url">
                    {({ field: { onChange, ...fieldProps } }: FieldProps<string>) => (
                      <Tooltip
                        condition={isTeamAdmin && values.enabled}
                        tooltip="URL of the application's health check endpoint"
                        placement="left"
                      >
                        <InputGroup>
                          <InputGroup.Text
                            className={classNames("d-none", "d-lg-flex", {
                              "text-muted": isTeamAdmin && values.enabled,
                              "text-very-muted": !isTeamAdmin || !values.enabled,
                            })}
                          >
                            <FontAwesomeIcon icon={faStethoscope} />
                          </InputGroup.Text>

                          <BootstrapForm.Control
                            type="text"
                            placeholder="https://api.example.com/healthz"
                            maxLength={2000}
                            disabled={!isTeamAdmin || !values.enabled}
                            ref={urlInputRef}
                            isInvalid={values.enabled && !!errors.url && touched.url}
                            onChange={async (e) => {
                              onChange(e);
                              setEditUrl(true);
                            }}
                            {...fieldProps}
                          />
                        </InputGroup>
                      </Tooltip>
                    )}
                  </Field>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </ListGroup.Item>
  );
}

type HealthChecksModalProps = {
  app?: ListAppsResponseItem;
  setApp: React.Dispatch<React.SetStateAction<ListAppsResponseItem | undefined>>;
};

function HealthChecksModal({ app, setApp }: HealthChecksModalProps) {
  const { teamPlan, refreshApps } = useGlobal();
  const [refreshCounter, setRefreshCounter] = useState(0);

  const formikRefs = useRef<{ [key: string]: React.RefObject<FormikProps<HealthCheckFormValues>> }>({});
  const healthChecksAllowed = !!teamPlan?.features.health_checks;

  const registerFormikRef = useCallback(
    (app_env: string, formikRef: React.RefObject<FormikProps<HealthCheckFormValues>>) => {
      formikRefs.current[app_env] = formikRef;
      setRefreshCounter((i) => i + 1);
    },
    [],
  );
  const unregisterFormikRef = useCallback((app_env: string) => {
    delete formikRefs.current[app_env];
    setRefreshCounter((i) => i + 1);
  }, []);

  const submitEnabled = useMemo(() => Object.keys(formikRefs.current).length > 0, [refreshCounter]);
  const submit = async () => {
    const submitPromises = Object.values(formikRefs.current).map((formikRef) => {
      if (formikRef.current) {
        return formikRef.current?.submitForm();
      }
    });
    await Promise.all(submitPromises);
    setTimeout(() => {
      if (
        Object.values(formikRefs.current).every((formikRef) => {
          return formikRef.current && Object.keys(formikRef.current?.errors).length === 0;
        })
      ) {
        setApp(undefined);
      }
    });
  };

  const afterClose = () => {
    refreshApps();
  };

  return (
    <Modal size="lg" show={app !== undefined} onHide={() => setApp(undefined)} onExited={() => afterClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Configure health checks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!healthChecksAllowed && (
          <UpgradePlanAlert>
            Health checks are a premium feature that is not included in the {teamPlan?.name}.
            <br />
            Please upgrade your team's plan to use health checks and enjoy additional benefits!
          </UpgradePlanAlert>
        )}
        <div className="fw-bold">{app?.name}</div>
        <p className="small text-muted mt-1 mb-1">
          Health checks monitor your application's availability by sending HTTP GET requests to the specified endpoint
          in 1&nbsp;minute intervals. If the endpoint returns a 200&nbsp;response within 5&nbsp;seconds, your
          application is considered healthy.
        </p>
        <p className="small text-muted mb-4">
          We recommend you use a dedicated health check endpoint that doesn't require authentication.
        </p>
        <ListGroup>
          {app?.envs.map((env) => (
            <HealthCheckListGroupItem
              key={env.id}
              app={app}
              env={env}
              registerFormikRef={registerFormikRef}
              unregisterFormikRef={unregisterFormikRef}
              disabled={!healthChecksAllowed}
            />
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        {!submitEnabled && (
          <Button variant="secondary" onClick={() => setApp(undefined)}>
            Close
          </Button>
        )}
        {submitEnabled && (
          <>
            <Button variant="secondary" onClick={() => setApp(undefined)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => submit()}>
              Submit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default HealthChecksModal;
