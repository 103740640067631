import { ActiveElement, Chart, ChartEvent, ChartOptions, TooltipItem } from "chart.js";
import { DateTime } from "luxon";
import { formatPeriodStep } from "./period";

export function getDurationBetweenLabels(labels: string[]) {
  if (!labels || labels.length < 2 || typeof labels[0] !== "string" || typeof labels[1] !== "string") {
    return 0;
  }
  const start = DateTime.fromISO(labels[0]);
  const end = DateTime.fromISO(labels[1]);
  return end.diff(start).as("milliseconds");
}

function getXScaleUnit(stepSizeHours: number) {
  if (stepSizeHours < 6) {
    return "hour" as const;
  } else if (stepSizeHours < 24 * 7) {
    return "day" as const;
  } else if (stepSizeHours <= 24 * 28) {
    return "week" as const;
  } else if (stepSizeHours < 24 * 30 * 12) {
    return "month" as const;
  } else {
    return "year" as const;
  }
}

type GetChartOptionsOptions = {
  labels: string[];
  title?: string;
  tooltipUnit?: string;
  setPeriod?: (period: string) => void;
};

export function getChartOptions({ labels, title, tooltipUnit, setPeriod }: GetChartOptionsOptions) {
  const stepSizeMillis = getDurationBetweenLabels(labels);
  const stepSizeHours = stepSizeMillis / 1000 / 60 / 60;
  const stepSizeIsMonth = stepSizeHours >= 24 * 28;
  const lastLabel = labels.at(-1);
  const periodEndWithin31Days = lastLabel && DateTime.fromISO(lastLabel).diffNow().as("days") > -31;
  const xScaleUnit = getXScaleUnit(stepSizeHours);
  const useAmericanDateFormat = navigator.language === "en-US";
  const dayMonthFormat = useAmericanDateFormat ? "MMM d" : "d MMM";
  const allowPeriodSelection = setPeriod && ((periodEndWithin31Days && stepSizeHours >= 1) || stepSizeHours > 24);

  const chartOptions: ChartOptions<"bar" | "line"> = {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: "DM Sans",
    },
    scales: {
      x: {
        type: "timeseries" as const,
        time: {
          unit: xScaleUnit,
          displayFormats: {
            day: dayMonthFormat,
            hour: "HH:mm",
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
          },
          color: "#adb5bd",
          maxRotation: 0,
          callback:
            stepSizeHours >= 6 && stepSizeHours < 24 * 28
              ? function (tickValue: string | number, index: number) {
                  const time = DateTime.fromMillis(tickValue as number);
                  const show = stepSizeHours < 24 ? time.hour === 0 : index % 2 === 0;
                  return show ? time.toFormat(dayMonthFormat) : null;
                }
              : Chart.defaults.scales.time.ticks.callback,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#faf9fb",
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
          },
          color: "#adb5bd",
        },
        display: true,
        beginAtZero: true,
      },
    },
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "index",
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: {
          family: "DM Sans",
          size: 14,
          weight: "bold" as const,
        },
      },
      tooltip: {
        mode: "index" as const,
        callbacks: {
          title: function (context: TooltipItem<"bar" | "line">[]) {
            const start = DateTime.fromMillis(context[0].parsed.x);
            const end = stepSizeIsMonth ? start.plus({ months: 1 }) : start.plus(stepSizeMillis);
            return formatPeriodStep(start, end);
          },
          label: function (context: TooltipItem<"bar" | "line">) {
            const value = context.parsed.y.toLocaleString();
            const unit = tooltipUnit ? " " + tooltipUnit : "";
            return ` ${context.dataset.label}: ${value}${unit}`;
          },
          footer: function (context: TooltipItem<"bar" | "line">[]) {
            if (allowPeriodSelection) {
              const start = DateTime.fromMillis(context[0].parsed.x);
              if ((start.diffNow().as("days") > -31 && stepSizeHours >= 1) || stepSizeHours > 24) {
                return "Click to select period";
              }
            }
          },
        },
        footerFont: {
          family: "DM Sans",
          size: 10,
          weight: "normal" as const,
          style: "italic" as const,
        },
        footerColor: "#ccc",
      },
      legend: {
        display: false,
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      if (event.native?.target && event.native.target instanceof HTMLElement) {
        event.native.target.style.cursor = elements.length > 0 && allowPeriodSelection ? "pointer" : "default";
      }
    },
    onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      if (event.native && allowPeriodSelection) {
        const res = chart.getElementsAtEventForMode(event.native, "x", { intersect: true }, true);
        if (res.length > 0) {
          const format = "yyyy-MM-dd'T'HH:mm:ss";
          const start = DateTime.fromISO(labels[res[0].index]);
          const end = stepSizeIsMonth ? start.plus({ months: 1 }) : start.plus(stepSizeMillis);
          if ((start.diffNow().as("days") > -31 && stepSizeHours >= 1) || stepSizeHours > 24) {
            setPeriod(`${start.toFormat(format)}|${end.toFormat(format)}`);
          }
        }
      }
    },
  };

  return chartOptions;
}
