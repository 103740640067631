import capitalize from "lodash/capitalize";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { DateTime } from "luxon";
import { TeamInviteItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";

type TeamInviteDetailsModalProps = {
  invite?: TeamInviteItem;
  setInvite: React.Dispatch<React.SetStateAction<TeamInviteItem | undefined>>;
};

function TeamInviteDetailsModal({ invite, setInvite }: TeamInviteDetailsModalProps) {
  const inviteCreatedDate = invite?.created_at ? DateTime.fromISO(invite?.created_at) : undefined;

  return (
    <Modal show={invite !== undefined} onHide={() => setInvite(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Invite details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "Email address": invite?.email,
            "Role": capitalize(invite?.role),
            "Invite sent": (
              <span title={inviteCreatedDate?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                {inviteCreatedDate?.toRelative()}
              </span>
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setInvite(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeamInviteDetailsModal;
