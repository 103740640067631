import { ReferenceLine, SparklinesLine } from "@lueton/react-sparklines";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ListTeamsAdminResponseItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";
import { getColor } from "../utils/colors";

type AdminTeamDetailsModalProps = {
  team?: ListTeamsAdminResponseItem;
  setTeam: React.Dispatch<React.SetStateAction<ListTeamsAdminResponseItem | undefined>>;
};

function AdminTeamDetailsModal({ team, setTeam }: AdminTeamDetailsModalProps) {
  const createdAt = team?.created_at ? DateTime.fromISO(team?.created_at) : undefined;
  const gracePeriodEnd = team?.plan_limits_grace_period_end
    ? DateTime.fromISO(team?.plan_limits_grace_period_end)
    : undefined;

  const currentInstanceCount = team?.instance_count_history.at(-1);
  const currentInstanceCountOverLimit =
    team?.plan.limit_instances && currentInstanceCount && currentInstanceCount > team.plan.limit_instances;
  const anyInstanceCountOverLimit = team?.instance_count_history.some(
    (count) => team.plan.limit_instances && count > team.plan.limit_instances,
  );
  const maxInstanceCount = team ? Math.max(...team.instance_count_history) : 0;
  const instancesSparklineColor = getColor(
    currentInstanceCount ? (currentInstanceCountOverLimit ? "danger" : "primary") : "secondary",
  );
  const instancesSparkline = (
    <SparklinesLine
      data={team?.instance_count_history}
      min={0}
      max={
        team?.plan.limit_instances && team.plan.limit_instances > maxInstanceCount
          ? team.plan.limit_instances
          : maxInstanceCount
      }
      fill={instancesSparklineColor}
      fillOpacity={0.1}
      stroke={instancesSparklineColor}
      strokeWidth={0.75}
      margin={0}
      width={300}
      height={50}
    >
      {anyInstanceCountOverLimit && team?.plan.limit_instances && (
        <ReferenceLine
          y={team.plan.limit_instances}
          stroke={getColor("danger")}
          strokeWidth={1}
          strokeDasharray="4,2"
        />
      )}
    </SparklinesLine>
  );

  const currentConsumerCount = team?.consumer_count_history.at(-1);
  const currentConsumerCountOverLimit =
    team?.plan.limit_consumers && currentConsumerCount && currentConsumerCount > team.plan.limit_consumers;
  const anyConsumerCountOverLimit = team?.consumer_count_history.some(
    (count) => team.plan.limit_consumers && count > team.plan.limit_consumers,
  );
  const maxConsumerCount =
    team && team.consumer_count_history.length > 0 ? Math.max(...team.consumer_count_history) : 0;
  const consumersSparklineColor = getColor(
    currentConsumerCount ? (currentConsumerCountOverLimit ? "danger" : "primary") : "secondary",
  );
  const consumersSparkline =
    team && team.instance_count_history.length > 0 ? (
      <SparklinesLine
        data={team.consumer_count_history}
        min={0}
        max={
          team.plan.limit_consumers && team.plan.limit_consumers > maxConsumerCount
            ? team.plan.limit_consumers
            : maxConsumerCount
        }
        fill={consumersSparklineColor}
        fillOpacity={0.1}
        stroke={consumersSparklineColor}
        strokeWidth={0.75}
        margin={0}
        width={300}
        height={50}
      >
        {anyConsumerCountOverLimit && team.plan.limit_consumers && (
          <ReferenceLine
            y={team.plan.limit_consumers}
            stroke={getColor("danger")}
            strokeWidth={1}
            strokeDasharray="4,2"
          />
        )}
      </SparklinesLine>
    ) : undefined;

  const loginCount = team?.login_count_history.reduce((a, b) => a + b, 0) || 0;
  const loginsSparkline = (
    <SparklinesLine
      data={team?.login_count_history}
      min={0}
      fill={getColor("primary")}
      fillOpacity={0.1}
      stroke={getColor("primary")}
      strokeWidth={0.75}
      margin={0}
      width={300}
      height={50}
    />
  );

  return (
    <Modal size="lg" show={team !== undefined} onHide={() => setTeam(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Team details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "ID": team?.id,
            "Name": team?.name,
            "Plan": team?.plan.name,
            "Contact": (
              <>
                <div>{team?.contact?.name}</div>
                <div className="small text-muted">{team?.contact?.email}</div>
              </>
            ),
            "Apps": (
              <>
                {team?.apps.map((app) => (
                  <div key={app.id}>
                    <span>{app.name}</span>
                    <span className="ms-2 small text-muted">({app.framework})</span>
                  </div>
                ))}
              </>
            ),
            "Instances": (
              <>
                <div>
                  <span>
                    {team?.instance_count_history.at(-1)} of {team?.plan.limit_instances} allowed
                  </span>
                  <span className="ms-2 small text-muted">(peak was {maxInstanceCount})</span>
                </div>
                <div>{instancesSparkline}</div>
              </>
            ),
            "Consumers": (
              <>
                <div>
                  <span>
                    {team?.consumer_count_history.at(-1) || 0}
                    {team?.plan.limit_consumers ? <> of {team?.plan.limit_consumers} allowed</> : undefined}
                  </span>
                  {maxConsumerCount > 0 ? (
                    <span className="ms-2 small text-muted">(peak was {maxConsumerCount})</span>
                  ) : undefined}
                </div>
                {maxConsumerCount > 0 ? <div>{consumersSparkline}</div> : undefined}
              </>
            ),
            "Users": team?.user_count,
            "Logins": (
              <>
                <div>{loginCount}</div>
                {loginCount > 0 ? <div>{loginsSparkline}</div> : undefined}
              </>
            ),
            "Last login": team?.last_login ? DateTime.fromISO(team?.last_login).toRelative() : undefined,
            "Created": (
              <span className="text-nowrap" title={createdAt?.toLocaleString(DateTime.DATETIME_FULL)}>
                {createdAt?.toLocaleString(DateTime.DATE_FULL)}
              </span>
            ),
            "Plan limits status": team?.plan_limits_status !== "ok" ? capitalize(team?.plan_limits_status) : undefined,
            "Grace period end":
              team?.plan_limits_status === "grace" && team?.plan_limits_grace_period_end ? (
                <>
                  <div>
                    <span>{gracePeriodEnd?.toLocaleString(DateTime.DATE_FULL)}</span>
                    <span className="ms-2 small text-muted">({gracePeriodEnd?.toRelative()})</span>
                  </div>
                </>
              ) : undefined,
          }}
          modalSize="lg"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setTeam(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdminTeamDetailsModal;
