import { useQuery } from "@tanstack/react-query";
import Placeholder from "react-bootstrap/Placeholder";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import TableCard from "../components/TableCard";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import "./ValidationErrorsTable.css";

type ValidationErrorsTableProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpoint: Endpoint;
  period: string;
  limit?: number;
};

function ValidationErrorsTable({
  app,
  env,
  consumerId,
  consumerGroupId,
  endpoint,
  period,
  limit = 100,
}: ValidationErrorsTableProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    period,
    timezone,
    limit,
  };
  const query = useQuery({
    queryKey: ["validationErrorsTable", queryParams],
    queryFn: () => backendClient!.errors.getValidationErrorsTable(queryParams),
    enabled: !!backendClient,
  });

  return (
    <TableCard
      responsive
      hover={query.isSuccess && query.data.length > 0}
      borderTop={false}
      className="ValidationErrorsTable align-middle"
    >
      <thead>
        <tr>
          <th>Location</th>
          <th>Field</th>
          <th>Message</th>
          <th>Type</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {query.data?.map((item, index) => (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{item.loc[0]}</td>
            <td>
              <code>{item.loc.slice(1).join(".")}</code>
            </td>
            <td style={{ minWidth: 160 }}>{item.msg}</td>
            <td>
              <code>{item.type}</code>
            </td>
            <td>{item.error_count}</td>
          </tr>
        ))}
        {query.isPending && (
          <Placeholder as="tr" animation="glow">
            <td width={140}>
              <Placeholder xs={6} />
            </td>
            <td>
              <Placeholder xs={8} className="text-danger" />
            </td>
            <td>
              <Placeholder xs={8} />
            </td>
            <td>
              <Placeholder xs={6} className="text-danger" />
            </td>
            <td width={140}>
              <Placeholder xs={4} />
            </td>
          </Placeholder>
        )}
        {query.isSuccess && query.data.length === 0 && (
          <tr>
            <td colSpan={5} className="py-6 text-center">
              No validation errors in the selected period.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

export default ValidationErrorsTable;
